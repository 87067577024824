import React from "react";

const AD = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">รถยนต์และบริการ</li>
                  <li className="breadcrumb-item">ผลิตภัณฑ์และบริการ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    ผู้ขับขี่เพิ่มเติม
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">แบ่งปันการขับขี่</p>
              <p className="text-avis mt-2">
                มีเพียงผู้ขับขี่หลักเท่านั้นที่ได้รับความคุ้มครองในการขับรถเช่าของเรา
                แต่คุณสามารถเพิ่มผู้ขับขี่เพิ่มเติมลงในการจองของคุณได้
              </p>
              <p className="text-avis mt-2">
                ผู้ขับขี่เพิ่มเติมต้องปฏิบัติตามข้อกำหนดและเงื่อนไขเดียวกันกับผู้ขับขี่หลักและจะได้รับความคุ้มครองภายใต้สัญญาเช่าเดียวกัน
              </p>
              <p className="text-avis mt-2">
                สิ่งที่สำคัญที่สุดที่ต้องจำ:
                ผู้ขับขี่ทุกคนจะต้องนำใบอนุญาตขับขี่, ใบอนุญาตขับขี่สากล
                และเอกสารอื่นๆ มาเมื่อถึงเวลารับรถเช่า
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AD;
