import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OWCR from "../components/onewaycarrental/OWCR";
import Title from "../Title";
import { useNavigate } from "react-router-dom";

const OneWayCarRental = () => {
  Title("One-Way Car Rental");
  let navigate = useNavigate();
  return (
    <>
      <HeaderOne />
      <OWCR navigate={navigate} />
      <FooterOne />
    </>
  );
};

export default OneWayCarRental;
