import React from "react";
import { Link } from "react-router-dom";

export default class DealsOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/us-offer1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const myIsi = {
      maxWidth: "30%",
      margin: "auto",
    };
    const myNav = {
      backgroundColor: "rgb(237, 85, 5)",
    };
    return (
      <>
        {/* Start Slogan One */}
        <section>
          <div className="container mt-3">
            <a href="#" className="row" style={myStyle}>
              <div className="col-xl-12 text-center pt-5 pb-5" style={myIsi}>
                <img
                  src={publicUrl + "assets/images/resources/us-offer.avif"}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </a>
          </div>

          <div className="container">
            <div className="row mt-4 border-bottom pb-3">
              <div className="col-lg-12 col-sm-12 ps-0">
                <h3 className="text-black">ข้อเสนอรถเช่าที่ดีที่สุด</h3>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <div className="row">
              <div className="col-xl-12 px-0">
                <div className="row">
                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl +
                            "assets/images/resources/avis-360-feature.jpg"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          Avis Inclusive -
                          การเช่ารถพร้อมค่าธรรมเนียมลดลงหรือไม่มีค่าธรรมเนียม
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            ไม่ว่าคุณจะต้องการรถเพียงไม่กี่วัน ช่วงสุดสัปดาห์
                            หรือสองสัปดาห์ ด้วย Avis Inclusive
                            คุณไม่ต้องกังวลเกี่ยวกับการเช่ารถของคุณ
                          </p>
                        </div>
                        <Link
                          to={
                            process.env.PUBLIC_URL + `/promotion/avis360detail`
                          }
                          className="btn btn-avis"
                        >
                          ประหยัดเลย
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer2.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          ประหยัดสูงสุด 10% เมื่อเช่า 5 วันขึ้นไป
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            เชื่อมั่นได้ว่ารถเช่าของเราจะสะอาดอย่างสมบูรณ์แบบ
                            และจะมีประสบการณ์การเช็คเอาท์ที่ปลอดภัย
                          </p>
                        </div>
                        <button className="btn btn-avis">ประหยัดเลย</button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer3.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          ประหยัดสูงสุด 10% เมื่อเช่า 5 วันขึ้นไป
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            เชื่อมั่นได้ว่ารถเช่าของเราจะสะอาดอย่างสมบูรณ์แบบ
                            และจะมีประสบการณ์การเช็คเอาท์ที่ปลอดภัย
                          </p>
                        </div>
                        <button className="btn btn-avis">ประหยัดเลย</button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer3.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          ประหยัดสูงสุด 10% เมื่อเช่า 5 วันขึ้นไป
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            เชื่อมั่นได้ว่ารถเช่าของเราจะสะอาดอย่างสมบูรณ์แบบ
                            และจะมีประสบการณ์การเช็คเอาท์ที่ปลอดภัย
                          </p>
                        </div>
                        <button className="btn btn-avis">ประหยัดเลย</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mt-3">
              <div className="col-sm-12 text-avis">
                <p>
                  ใครที่ไม่ชอบประหยัดเงิน
                  โดยเฉพาะเมื่อเงินนั้นสามารถนำไปซื้อของที่ระลึกหรือแม้แต่ทานอาหารเย็นได้?
                  Avis รู้ว่าการเดินทางอาจมีค่าใช้จ่ายสูง
                  และเพื่อช่วยให้คุณใช้จ่ายงบประมาณการเดินทางของคุณให้คุ้มค่าที่สุด
                  เราได้จัดเตรียมโปรโมชั่นรถเช่าและส่วนลดต่าง ๆ
                  สำหรับการเช่ารถที่ราคาย่อมเยาอยู่แล้ว
                  ไม่ว่าคุณจะต้องการโปรโมชั่นรถเช่าที่เชื่อถือได้ที่สามารถใช้ซ้ำได้
                  หรือดีลรถเช่าแบบนาทีสุดท้ายสำหรับการพักผ่อนสุดสัปดาห์
                  คุณก็แน่ใจว่าจะพบคูปองรถเช่า Avis
                  ที่ตรงกับความต้องการการขนส่งของคุณ เมื่อคุณทำการจอง
                  การประหยัดจะถูกนำมาใช้โดยอัตโนมัติและแสดงในสรุปอัตราค่าบริการของคุณ
                  ข้อเสนอพิเศษสำหรับรถเช่าบางรายการไม่สามารถใช้กับทุกกลุ่มรถได้
                  และอาจมีวันปิดหรือข้อจำกัดต่าง ๆ
                  จองรถวันนี้และประหยัดสำหรับการเดินทางครั้งหน้าโดยใช้คูปองรถเช่า
                  Avis ของคุณ
                </p>
                <p className="mt-3">
                  ข้อเสนอรถเช่าของเราไม่ได้หยุดอยู่ที่พรมแดนสหรัฐอเมริกา
                  ดังนั้นคุณสามารถใช้ประโยชน์จากโปรโมชั่นที่แข่งขันได้ของเราในการเดินทางระหว่างประเทศครั้งต่อไปของคุณ
                  ค้นพบดีลและคูปองสำหรับรถเช่าในส่วนต่าง ๆ ของโลก เช่น เอเชีย
                  ออสเตรเลีย แคนาดา ยุโรป ลาตินอเมริกา และนิวซีแลนด์
                  ทำไมไม่ประหยัดในประสบการณ์การเดินทางทั้งหมดของคุณ?
                  รวมส่วนลดสมาชิก Preferred กับโปรโมชั่นอื่น ๆ
                  จากพันธมิตรของเราที่ช่วยให้คุณเพลิดเพลินกับโรงแรมที่ดีที่สุดและจุดหมายปลายทางท่องเที่ยวยอดนิยม
                  แน่นอนว่า เราไม่ลืมส่วนลดสำหรับสมาชิก AARP ที่ภักดีของเรา
                  และเพื่อเป็นการแสดงความขอบคุณ
                  ยังมีส่วนลดให้กับสมาชิกทหารที่กำลังปฏิบัติหน้าที่และทหารผ่านศึกอยู่เสมอ
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 text-avis">
                <h2 className="text-black">คำถามที่พบบ่อยเกี่ยวกับดีลรถเช่า</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        style={{ textTransform: "uppercase" }}
                      >
                        ฉันจะหาดีลที่ดีที่สุดสำหรับรถเช่าได้อย่างไร?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        มีวิธีต่าง ๆ ในการหาดีลที่ดีที่สุดสำหรับรถเช่า
                        สุดท้ายคือไปที่แท็บ "Offers" สำหรับคูปองรถเช่า Avis
                        เมื่อคุณอยู่ในหน้ "US Offers"
                        คุณจะพบคูปองและโปรโมชั่นรถเช่าหลากหลาย นอกจากนี้
                        วิธีการประหยัดค่าเช่าที่จะมาถึงในอนาคตคือการใช้ประโยชน์จาก
                        Avis Best Price Pledge Avis Best Price Pledge
                        คือคำมั่นสัญญาของเราที่จะจับคู่ราคาการเช่ารถ Avis
                        ที่ต่ำกว่าในเว็บไซต์ของบุคคลที่สาม
                        พร้อมให้คุณได้รับส่วนลดเพิ่มเติม
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        style={{ textTransform: "uppercase" }}
                      >
                        ฉันจะหารถเช่าที่ถูกที่สุดได้อย่างไร?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        เมื่อมองหาข้อเสนอพิเศษสำหรับรถเช่าและวิธีอื่น ๆ
                        ในการลดค่าเช่า มีเคล็ดลับหลัก 3 ข้อที่ต้องจดจำ. <br />
                        1. จ่ายตอนนี้: Avis ให้คุณเลือกตัวเลือก "Pay Now"
                        เพื่อจ่ายค่ารถเช่าเมื่อทำการจองแทนที่จะจ่ายตอนรับรถ
                        เมื่อคุณเลือกจ่ายตอนนี้แทนที่จะจ่ายทีหลัง
                        คุณสามารถประหยัดได้ถึง 30% จากอัตราค่าบริการของคุณ.{" "}
                        <br />
                        2. เปรียบเทียบราคา: ในการเปรียบเทียบราคาของรถต่าง ๆ
                        คุณจะต้องกรอกแบบฟอร์มการจองและคลิก "Select My Car"
                        เมื่อคุณป้อนวันที่เดินทางและสถานที่และส่งแบบฟอร์มแล้ว
                        คุณจะสามารถเลื่อนดูตัวเลือกรถเช่าและราคาได้
                        รถที่มีขนาดเล็ก เช่น รุ่นประหยัด
                        เป็นรถที่มักจะมีราคาถูกกว่า
                        คุณยังสามารถย้อนกลับและเปลี่ยนวันที่และสถานที่ในแบบฟอร์มการจองเพื่อดูว่าราคาจะขึ้นหรือลด.{" "}
                        <br />
                        3. วางแผนล่วงหน้า:
                        โดยทั่วไปราคารถเช่าจะสูงขึ้นเมื่อวันที่จองใกล้เข้ามา
                        ทำการจองล่วงหน้าเพื่อรักษาราคาของคุณ
                        การเช่ารถไป-กลับมักจะถูกกว่า
                        ดังนั้นจึงควรคำนึงถึงสิ่งนี้เมื่อวางแผนการเดินทาง.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        style={{ textTransform: "uppercase" }}
                      >
                        เวลาที่ดีที่สุดในการเช่ารถคือช่วงเวลาใด?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        คลังรถเช่า Avis
                        มีความพร้อมใช้งานของรถเสมอและดีที่สุดคือต้องโทรหาสถานที่ของคุณล่วงหน้าหากคุณยังไม่มีการจอง
                        แต่โดยทั่วไปแล้ว
                        การเลือกรถที่ใหญ่ที่สุดจะมีให้เลือกมากที่สุดเมื่อ Avis
                        เปิดทำการ ก่อนที่คุณจะมาถึง
                        ตรวจสอบเวลาการให้บริการรถเช่าที่สำนักงาน Avis
                        ท้องถิ่นของคุณ คุณสามารถทำการจองในวันเดียวกันได้เสมอ
                        ตราบใดที่มันเป็นเวลาอย่างน้อยหนึ่งชั่วโมงก่อนเวลารับรถของคุณ.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        style={{ textTransform: "uppercase" }}
                      >
                        การเช่ารถออนไลน์ถูกกว่าการเช่าแบบตัวต่อตัวหรือไม่?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        แม้ว่าราคารถเช่าจะเปลี่ยนแปลงจากวันต่อวัน
                        แต่โดยทั่วไปคุณจะพบดีลที่ดีกว่าถ้าคุณเช่ารถออนไลน์แทนที่จะเช่าแบบตัวต่อตัว
                        ข้อเสนอพิเศษสำหรับรถเช่าส่วนใหญ่ของเราจะมีเฉพาะออนไลน์
                        และถ้าคุณเลือก "Pay Now" เมื่อทำการจองออนไลน์
                        คุณสามารถประหยัดได้ถึง 30% จากอัตราค่าบริการ
                        อย่างไรก็ตาม
                        ทุกครั้งบางครั้งคุณอาจพบดีลรถเช่าแบบนาทีสุดท้ายเมื่อเช่าแบบตัวต่อตัว.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        style={{ textTransform: "uppercase" }}
                      >
                        การเช่ารถที่สนามบินถูกกว่าการเช่ารถที่นอกสนามบินหรือไม่?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        เมื่อเปรียบเทียบราคาตรง ๆ
                        อาจบางครั้งจะคุ้มกว่าที่จะเช่ารถนอกสนามบินแทนที่จะเช่าที่สนามบิน
                        อย่างไรก็ตาม หากคุณพิจารณาค่าใช้จ่ายอื่น ๆ เช่น
                        แท็กซี่หรือบริการแชร์รถ พวกเขาอาจจะมีราคาคล้ายกัน
                        สำหรับผู้ที่ต้องการเช่ารถจาก Avis ที่สนามบิน
                        ค้นหาคูปองและส่วนลดเพื่อทำดีลให้ดียิ่งขึ้น.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* End Slogan One */}
      </>
    );
  }
}
