import React from "react";
import { Link } from "react-router-dom";
import SignModal from "../../common/header/SignModal";
import Axios from "axios";
import { API_URL } from "../../core/constant";
import { getToken } from "../../core/functions";
import avisInlusive from "../../avis_inclusive_country.json";

export default class vmc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      last_name: "",
      bookingCode: "",
      countryCode: "ID",
      isShowBooking: false,
      bookingData: {},
      isLoading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const thiss = this;
    const state = this.state;
    const props = this.props;
    thiss.setState({ isLoading: true });
    getToken();
    Axios.get(
      `https://stage.abgapiservices.com/cars/reservation/v2?brand=Avis&last_name=${this.state.last_name}&confirmation_number=${this.state.bookingCode}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bebe"),
          client_id: "04e19f27",
        },
      }
    )
      .then(function (response) {
        props.navigate("/view_booking", {
          state: {
            bookingData: response.data,
          },
        });
        return response.data;
      })
      .then(function (data) {
        Axios.get(
          API_URL +
            "/api/view-booking?bookingNumber=" +
            data.reservation?.confirmation?.number
        )
          .then((response2) => {
            console.log(response2.data.booking);
            props.navigate("/view_booking", {
              state: {
                bookingData: data,
                bookingDataApi: response2.data.booking,
              },
            });
            thiss.setState({ isLoading: false });
          })
          .catch((error2) => {
            console.log(error2);
            alert("Booking not found or Please try againnn");
            thiss.setState({ isLoading: false });
            getToken();
            window.location.reload();
          });
      })
      .catch(function (error) {
        alert("Failed To View Reservation!");
        thiss.setState({ isLoading: false });
        getToken();
        window.location.reload();
      })
      .finally(function () {
        thiss.setState({ isLoading: false });
      });
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/reservation.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const myLink = {
      color: "rgb(212, 0, 42)",
    };

    return (
      <>
        {/* Start Slogan One */}
        <section style={myStyle}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center pt-5">
                <h2 style={{ color: "#000" }}>ดู / ยกเลิก</h2>
              </div>
            </div>
            <div
              className="row mt-5 mb-5"
              style={{
                backgroundColor: "#fff",
                textAlign: "center",
              }}
            >
              <div className="col-sm-12 p-4 text-avis">
                {this.state.isShowBooking ? (
                  <>
                    <h3 style={myLink}>การจองของคุณ</h3>

                    <div
                      style={{ backgroundColor: "#f0f1f2" }}
                      className="mt-3"
                    >
                      <h5 className="text-start p-3 text-dark fw-bold">
                        <b>Your reference: 4046-5154-ID-2</b>
                      </h5>

                      <div
                        style={{
                          borderTop: "1px solid #dedfe0",
                          borderBottom: "1px solid #dedfe0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{ color: "#000", flexGrow: "1.5" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>When & Where</span>
                          <br />
                          <br />
                          18 Sep 10:00 → 19 Sep 10:00
                          <br />
                          London Heathrow Airport T2,3Tand 4T
                        </div>
                        <div
                          style={{ color: "#000", flexGrow: "2" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>Vehicle</span>
                          <br />
                          <br />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p>Small</p>
                            <p>$151</p>
                          </div>
                          <p style={{ fontSize: "13px" }}>
                            Flat 500 Group Car A
                          </p>

                          <img
                            className="mt-3"
                            src="https://www.avis.com/content/dam/cars/s/2020/ford/2020-ford-transit-350-xlt-low-roof-passenger-van-white.png"
                            width={200}
                          />
                        </div>
                        <div
                          style={{ color: "#000", flexGrow: "1.5" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>Extras</span>
                          <br />
                          <br />

                          <p>None Selected</p>
                        </div>
                      </div>

                      <h5 className="text-end p-3 fw-bold text-dark">
                        Total $550
                      </h5>
                    </div>

                    <div
                      className="p-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        width: "100%",
                      }}
                    >
                      <div className="me-3" style={{ flexGrow: "1" }}>
                        <h5 className="text-start text-dark fw-bold">
                          <b>When you collect your car</b>
                        </h5>

                        <p style={{ color: "black", textAlign: "left" }}>
                          One of your payment cards must be in the name of the
                          main driver
                          <br /> (unless booked on a centrally billed company
                          credit card or account)
                          <br /> and you'll need to present the following at the
                          counter:
                        </p>

                        <ul className="mt-3 text-start">
                          <li>
                            <span style={myLink}>&#10003;</span> A valid driving
                            license
                          </li>
                          <li>
                            <span style={myLink}>&#10003;</span> 1 payment card
                          </li>
                        </ul>
                      </div>
                      <div className="flex-grow-1" style={{ flexGrow: "2" }}>
                        <p className="text-start text-dark fw-bold">
                          Your reservation includes:
                        </p>

                        <p style={{ color: "black", textAlign: "left" }}>
                          (inclusions are subject to your agreed/contracted
                          rates)
                        </p>

                        <ul className="mt-3 text-start">
                          <li>
                            <span style={myLink}>&#10003;</span> A valid driving
                            license
                          </li>
                          <li>
                            <span style={myLink}>&#10003;</span> 1 payment card
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      style={{ backgroundColor: "#f0f1f2" }}
                      className="mt-3 p-3"
                    >
                      <h5 className="text-start text-dark fw-bold">
                        <b>Getting to your rental station</b>
                      </h5>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <div>
                          <h6 className="text-start mt-3 fw-bold text-dark">
                            Pick Up
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                textAlign: "left",
                                marginRight: "10px",
                              }}
                            >
                              London Heathrow Airport T2,3Tand 4T
                              <br />
                              London
                              <br />
                              TW6 2GW
                              <br />
                              United Kingdom
                            </p>
                            <p style={{ color: "black", textAlign: "left" }}>
                              Opening times on your pick up date:
                              <br /> 05:00 - 23:59
                            </p>
                          </div>

                          <p className="text-start mt-3">Tel: 08324733432</p>
                        </div>
                        <div>
                          <h6 className="text-start mt-3 fw-bold text-dark">
                            Drop Off
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                textAlign: "left",
                                marginRight: "10px",
                              }}
                            >
                              London Heathrow Airport T2,3Tand 4T
                              <br />
                              London
                              <br />
                              TW6 2GW
                              <br />
                              United Kingdom
                            </p>
                            <p style={{ color: "black", textAlign: "left" }}>
                              Opening times on your pick up date:
                              <br /> 05:00 - 23:59
                            </p>
                          </div>

                          <p className="text-start mt-3">Tel: 08324733432</p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "20px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-lg"
                        style={{
                          backgroundColor: "#f0f1f2",
                          color: "black",
                          marginRight: "10px",
                        }}
                        onClick={() => this.setState({ isShowBooking: false })}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg"
                        style={{
                          backgroundColor: "rgb(212, 0, 42)",
                          color: "white",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p style={{ color: "#000" }}>
                      การจองที่ทำผ่าน Avis-id.com สามารถดูได้
                      แก้ไขหรือยกเลิกโดยป้อนข้อมูลด้านล่างหรือ โดย{" "}
                      <a
                        href="#"
                        onClick={() => <SignModal />}
                        style={{ color: "rgb(212, 0, 42)" }}
                      >
                        เข้าสู่ระบบ
                      </a>{" "}
                      และเข้าถึงหน้าการเช่าของคุณ
                    </p>

                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <div className="row flex-row mt-3 pb-3">
                        <div className="col-sm-6">
                          <div className="form-group d-flex flex-column align-items-start">
                            <label htmlFor="lastName">นามสกุล</label>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              style={{
                                backgroundColor: "#ebeff5",
                                fontSize: "20px",
                              }}
                              id="lastName"
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                              value={this.state.last_name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group d-flex flex-column align-items-start">
                            <label htmlFor="reservNum">หมายเลขการจอง</label>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              style={{
                                backgroundColor: "#ebeff5",
                                fontSize: "20px",
                              }}
                              id="reservNum"
                              onChange={(e) =>
                                this.setState({ bookingCode: e.target.value })
                              }
                              value={this.state.bookingCode}
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary rounded-0"
                        disabled={this.state.isLoading}
                        style={{
                          backgroundColor: "rgb(212, 0, 42)",
                          boxShadow: "none",
                        }}
                      >
                        ค้นหาการจอง
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-5 pb-5"></div>
          </div>
        </section>
        {/* End Slogan One */}
      </>
    );
  }
}
