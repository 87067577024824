import React from "react";

export default class FP extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">รถยนต์และบริการ</li>
                    <li className="breadcrumb-item">ผลิตภัณฑ์และบริการ</li>
                    <li className="breadcrumb-item">สหรัฐอเมริกาและแคนาดา</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      แผนการเติมน้ำมัน
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    แผนการเติมน้ำมันและตัวเลือกบริการเติมน้ำมัน
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  รายละเอียดแผนการเติมน้ำมัน
                </h3>
                <p className="text-avis mt-2">
                  <b>จุดแวะที่น้อยลงบนเส้นทางกลับบ้านของคุณ</b>
                </p>
                <ul>
                  <li className="text-avis mt-2">
                    • ตัวเลือกการเติมน้ำมันที่เหมาะกับความต้องการของคุณ
                  </li>
                  <li className="text-avis mt-2">• ชำระเงินล่วงหน้า</li>
                  <li className="text-avis mt-2">
                    • ข้ามการเติมน้ำมันและประหยัดเวลาการเดินทางของคุณ
                  </li>
                </ul>
                <p className="text-avis mt-2">
                  เมื่อถึงเวลาที่ต้องเติมน้ำมัน
                  เลือกวิธีที่เหมาะสมกับตารางเวลาของคุณและงบประมาณของคุณ:
                </p>
                <ul>
                  <li className="text-avis mt-2">• ตัวเลือกบริการเติมน้ำมัน</li>
                  <li className="text-avis mt-2">• บริการตนเอง*</li>
                  <li className="text-avis mt-2">
                    • บริการ EZFuel® และค่าบริการเติมน้ำมัน*
                  </li>
                </ul>
                <p className="text-avis mt-2">
                  * สามารถขอได้ที่เคาน์เตอร์เช่ารถ
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  ตัวเลือกบริการเติมน้ำมัน
                </h3>
                <p className="text-avis mt-2">
                  ประหยัดเวลาและความยุ่งยากด้วยการเพิ่มตัวเลือกบริการเติมน้ำมันในการจองออนไลน์ครั้งต่อไปของคุณ
                  เพียงชำระเงินล่วงหน้าสำหรับถังน้ำมันเต็มถัง
                  และนำรถกลับมาพร้อมน้ำมันถังเปล่า
                  คุณไม่จำเป็นต้องค้นหาปั๊มน้ำมันก่อนคืนรถ
                  อัตราค่าบริการนี้เปรียบเทียบได้กับราคาน้ำมันตามท้องตลาดปัจจุบัน
                </p>
                <p className="text-avis mt-2">
                  หากคุณเลือกตัวเลือกนี้
                  คุณควรนำรถกลับมาพร้อมน้ำมันถังเปล่าให้มากที่สุด
                  เพราะไม่มีการให้เครดิตหรือคืนเงินสำหรับน้ำมันที่ไม่ได้ใช้ในถัง
                </p>
                <p className="text-avis mt-2">
                  หากตัวเลือกบริการเติมน้ำมันไม่ใช่สิ่งที่คุณต้องการ
                  คุณสามารถเลือกตัวเลือกการเติมน้ำมันอื่น ๆ
                  ที่นิยมได้ในเวลาที่เช่ารถ:
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">บริการตนเอง</h3>
                <p className="text-avis mt-2">
                  ไม่แน่ใจว่าคุณจะขับรถไกลแค่ไหน แต่มีแผนจะหยุดเติมน้ำมัน?
                  อย่ารับตัวเลือกบริการเติมน้ำมัน
                  เพียงแค่ซื้อน้ำมันเองก่อนคืนรถให้ Avis พร้อมน้ำมันเต็มถัง
                  ส่งใบเสร็จรับเงินการซื้อน้ำมันของคุณ
                  แล้วคุณจะไม่ต้องเสียค่าบริการเติมน้ำมันเลย ง่ายใช่ไหม?
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  EZFuel® และค่าบริการเติมน้ำมัน
                </h3>
                <p className="text-avis mt-2">
                  ขับรถน้อยกว่า 75 ไมล์? ด้วยบริการเติมน้ำมันล่าสุดของเรา
                  ลูกค้าที่ขับรถน้อยกว่า 75 ไมล์จะจ่ายค่าบริการน้ำมันแบบเหมา
                  และสามารถคืนรถได้โดยไม่ต้องหยุดที่ปั๊มน้ำมัน เลือกตัวเลือกนี้
                  แล้วเราจะคิดค่าบริการเหมาที่{" "}
                  <b>$15.99, ($17.99 หากคุณเช่ารถในแคลิฟอร์เนีย).</b>
                </p>
                <p className="text-avis mt-2">
                  หากคุณ <b>**เติมน้ำมันเต็มถัง***</b>{" "}
                  และแสดงใบเสร็จรับเงินการซื้อน้ำมัน ค่าบริการ <b>EZFuel</b>{" "}
                  จะถูกยกเลิกเมื่อคุณคืนรถ
                </p>
                <p className="text-avis mt-2">
                  บริการ EZFuel ไม่มีให้บริการในทุกสถานที่
                  โปรดติดต่อสถานที่เช่าของคุณเพื่อตรวจสอบความพร้อมของบริการนี้
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
