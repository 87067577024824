import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">รถยนต์ & บริการ</a>
                    </li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      โปรแกรม
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-4">
              <div className="col-sm-12 text-black">
                <h1 className="text-up text-black my-5">โปรแกรม</h1>
                <p className="mb-2 text-avis">
                  รับไมล์หรือคะแนนและเพลิดเพลินกับส่วนลดพิเศษผ่านความร่วมมือของเรา
                  Avis มีความร่วมมือหลากหลายกับสายการบิน โรงแรม และบริษัทอื่น ๆ
                  ที่เลือก
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">สายการบินกาตาร์</h1>
                <p className="mb-2 text-avis">
                  สมาชิก Burgundy สามารถรับ 2 base Avios ต่อ USD ที่ใช้จ่าย
                  สมาชิก Silver สามารถรับ 2 base Avios และ 1 bonus Avios ต่อ USD
                  ที่ใช้จ่าย สมาชิก Gold สามารถรับ 2 base Avios และ 2 bonus
                  Avios ต่อ USD ที่ใช้จ่าย สมาชิก Platinum สามารถรับ 2 base
                  Avios และ 3 bonus Avios ต่อ USD ที่ใช้จ่าย
                </p>
                <p className="mb-2 text-avis">
                  โปรดระบุหมายเลขสมาชิกของคุณระหว่างการจองและแสดงบัตรสมาชิกของคุณที่เคาน์เตอร์เมื่อรับรถเพื่อรับไมล์
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">สายการบินสิงคโปร์</h1>
                <p className="mb-2 text-avis">
                  สมาชิกทุกระดับสามารถรับ 500
                  ไมล์ต่อการเช่าที่มีคุณสมบัติตามอัตราราคาพักผ่อนแบบค้าปลีก
                  โปรดระบุหมายเลขสมาชิกของคุณระหว่างการจองและแสดงบัตรสมาชิกของคุณที่เคาน์เตอร์เมื่อรับรถเพื่อรับไมล์
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">สายการบินตุรกี</h1>
                <p className="mb-2 text-avis">
                  สมาชิก Classic, Elite และ Garanti Shop & Miles Card สามารถรับ
                  500 ไมล์ต่อการเช่าที่มีคุณสมบัติ
                </p>
                <p className="mb-2 text-avis">
                  โปรดระบุหมายเลขสมาชิกของคุณระหว่างการจองและแสดงบัตรสมาชิกของคุณที่เคาน์เตอร์เมื่อรับรถเพื่อรับไมล์
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">มาสเตอร์การ์ด</h1>
                <p className="mb-2 text-avis">
                  สมาชิก World Elite สามารถรับส่วนลดสูงสุด 35% ทั่วโลก สมาชิก
                  World, Platinum, Gold สามารถรับส่วนลดสูงสุด 20% ทั่วโลก สมาชิก
                  Business & Sberbank 1 สามารถรับส่วนลดสูงสุด 20% ทั่วโลก
                </p>
                <p className="mb-2 text-avis">
                  โปรดระบุหมายเลขสมาชิกของคุณระหว่างการจองและแสดงบัตรสมาชิกของคุณที่เคาน์เตอร์เมื่อรับรถเพื่อรับไมล์
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">วีซ่า</h1>
                <p className="mb-2 text-avis">
                  สมาชิก Infinite, Infinite Privilege & Signature
                  สามารถรับส่วนลดสูงสุด 35% ตามสิทธิประโยชน์ของ Avis President’s
                  Club สมาชิก Platinum สามารถรับส่วนลดสูงสุด 20%
                  ตามสิทธิประโยชน์ของ Avis Preferred Plus สมาชิก Platinum
                  Business (สมาชิกบัตรรัสเซียเท่านั้น) สามารถรับส่วนลดสูงสุด 20%
                  ตาม Avis Preferred non-loyalty สมาชิก Gold & Rewards
                  สามารถรับส่วนลดสูงสุด 20% ตาม Avis Preferred Plus สมาชิก
                  Business สามารถรับส่วนลดสูงสุด 20%
                </p>
                <p className="mb-2 text-avis">
                  โปรดระบุหมายเลขสมาชิกของคุณระหว่างการจองและแสดงบัตรสมาชิกของคุณที่เคาน์เตอร์เมื่อรับรถเพื่อรับไมล์
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
