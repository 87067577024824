import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import axios from "axios";
import HeaderAlt from "../common/header/HeaderAlt";
import { generateRandomNumbers } from "../core/functions";
import { API_URL, BRAND } from "../core/constant.js";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { format } from "date-fns";

const ConfirmBooking = () => {
  const [header, setHeader] = useState([]);
  const locate = useLocation();
  const queryParams = new URLSearchParams(locate.search);

  const orderId = queryParams.get("order_id");

  const make = localStorage.getItem("make");
  const name = localStorage.getItem("name");
  const image = localStorage.getItem("image");
  const model = localStorage.getItem("model");
  const transmission = localStorage.getItem("transmission");
  // const extraData = JSON.parse(localStorage.getItem("extraData"));
  const sipp_code = localStorage.getItem("vehicle_sipp_code");

  useEffect(() => {
    const fetchBookingSearch = async () => {
      try {
        const res = await axios.post(
          `${API_URL}/api/booking/search`,
          {
            book: orderId,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (res.status === 200) {
          console.log(res.data);
          setHeader(res.data.booking.header);
        }
      } catch (error) {
        console.error(error);
      }
    };
  });

  const [confirm_number, setConfirmNumber] = useState("");
  const [pickup_date, setPickupDate] = useState(
    localStorage.getItem("pickup_date")
  );
  const [pickup_time, setPickupTime] = useState(
    localStorage.getItem("pickup_time")
  );
  const [pickup_location, setPickupLocation] = useState(
    localStorage.getItem("nameLocation")
  );
  const [dropoff_date, setDropoffDate] = useState(
    localStorage.getItem("dropoff_date")
  );
  const [dropoff_time, setDropOffTime] = useState(
    localStorage.getItem("dropoff_time")
  );
  const [dropoff_location, setDropoffLocation] = useState(
    localStorage.getItem("nameLocation")
  );
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [total_price, setTotalPrice] = useState(localStorage.getItem("total"));
  const [vehicle_sipp_code, setVehicleSippCode] = useState("");
  const [rate_code, setRateCode] = useState("");
  const [location, setLocation] = useState(
    localStorage.getItem("nameLocation")
  );

  const [pickupLat, setPickupLat] = useState("");
  const [pickupLng, setPickupLng] = useState("");
  const [dropoffLat, setDropoffLat] = useState("");
  const [dropoffLng, setDropoffLng] = useState("");
  const [requestTime, setRequestTime] = useState(
    localStorage.getItem("requestTime")
  );

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [errorGetCurrentLocation, setErrorGetCurrentLocation] = useState("");

  const cityLocation = localStorage.getItem("cityLocation") ?? "";
  const codeLocation = localStorage.getItem("codeLocation") ?? "";
  const addressLine1Location =
    localStorage.getItem("addressLine1Location") ?? "";
  const addressLine2Location =
    localStorage.getItem("addressLine2Location") ?? "";
  const postalCodeLocation = localStorage.getItem("postalCodeLocation") ?? "";
  const countryCodePick = localStorage.getItem("countryCodePick") ?? "";
  const telephoneLocation = localStorage.getItem("telephoneLocation") ?? "";

  useEffect(() => {
    const fetchData = async () => {
      const pickupLocation = localStorage.getItem("pickup_location");
      const clientId = "04e19f27";

      if (pickupLocation) {
        try {
          const response = await axios.get(
            `https://stage.abgapiservices.com/cars/locations/v1?keyword=${pickupLocation}&client_id=${clientId}`,
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: clientId,
              },
            }
          );

          const pickupData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code === localStorage.getItem("pickup_location")
          );

          const dropoffData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code === localStorage.getItem("dropoff_location")
          );

          setPickupLat(pickupData.address.lat);
          setPickupLng(pickupData.address.long);
          setDropoffLat(dropoffData.address.lat);
          setDropoffLng(dropoffData.address.long);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      setErrorGetCurrentLocation(
        "Geolocation is not supported in this browser"
      );
    }
  }, []);

  const successGetCurrentLocation = (position) => {
    setCurrentLatitude(position.coords.latitude);
    setCurrentLongitude(position.coords.longitude);
    setErrorGetCurrentLocation(null);
  };

  const errorMessageGetCurrentLocation = (error) => {
    setErrorGetCurrentLocation(error.message);
  };

  const getPickUpDirectionUrl = () => {
    if (currentLatitude && currentLongitude && pickupLat && pickupLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${pickupLat},${pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  const getDropOffDirectionUrl = () => {
    if (currentLatitude && currentLongitude && dropoffLat && dropoffLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${dropoffLat},${dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  let pickupDate = new Date(localStorage.getItem("pickup_date"));
  let dropoffDate = new Date(localStorage.getItem("dropoff_date"));

  let pickupDateYear = pickupDate.getFullYear();
  let pickupDatemonth = (pickupDate.getMonth() + 1).toString().padStart(2, "0");
  let pickupDateday = pickupDate.getDate().toString().padStart(2, "0");

  let dropoffDateYear = dropoffDate.getFullYear();
  let dropoffDatemonth = (dropoffDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  let dropoffDateday = dropoffDate.getDate().toString().padStart(2, "0");

  pickupDate = pickupDateYear + "-" + pickupDatemonth + "-" + pickupDateday;
  dropoffDate = dropoffDateYear + "-" + dropoffDatemonth + "-" + dropoffDateday;

  let publicUrl = process.env.PUBLIC_URL + "/";

  const customIcon = new Icon({
    iconUrl: publicUrl + "assets/images/icon/marker.png",
    iconSize: [38, 38],
  });

  var dataReserve = {
    product: {
      brand: BRAND,
      iata_number: "0113105R",
    },
    transaction: {
      transaction_id: generateRandomNumbers().toString(),
    },
    reservation: {
      email_notification: true,
      pickup_date: format(
        localStorage.getItem("pickup_date"),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      pickup_location: localStorage.getItem("nameLocation"),
      dropoff_date: format(
        localStorage.getItem("dropoff_date"),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      dropoff_location: localStorage.getItem("dropoff_location"),
      vehicle_sipp_code: localStorage.getItem("vehicle_sipp_code"),
    },
    rate_totals: {
      rate: {
        rate_code: localStorage.getItem("rate_code"),
        country_code: "ID",
      },
    },
    customer: {
      contact: {
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
        telephone: localStorage.getItem("telephone")
          ? localStorage.getItem("telephone").toString()
          : "",
        email: localStorage.getItem("email"),
        age: parseInt(localStorage.getItem("age")),
      },
      address: {
        country_code: "ID",
        address_line_1:
          localStorage.getItem("addressPick") == null
            ? ""
            : localStorage.getItem("addressPick"),
        address_line_2:
          localStorage.getItem("addressPick2") == null
            ? ""
            : localStorage.getItem("addressPick2"),
        address_line_3: "",
        city:
          localStorage.getItem("cityPick") == null
            ? ""
            : localStorage.getItem("cityPick"),
        postal_code:
          localStorage.getItem("postalPick") == null
            ? ""
            : localStorage.getItem("postalPick"),
      },
    },
    payment_preference: {
      type: "CentralBillingAccount",
      id: generateRandomNumbers().toString(),
      fixed_value: "FixedValue",
      electronic_indicator: true,
      amount: parseInt(localStorage.getItem("amount")),
      currency: "USD",
    },
  };

  console.log("tes", localStorage.getItem("pickup_date"), "tes");

  console.log(sipp_code);

  return (
    <div className="px-5">
      {/* Header */}
      <section
        id="header"
        className="d-flex justify-content-between mt-4 border border-danger px-4 py-3"
      >
        <div className="d-flex d-grid gap-3">
          <Link to={process.env.PUBLIC_URL + `/`}>
            <img
              src={publicUrl + "assets/images/icon/avis.png"}
              alt=""
              width={80}
              height={30}
            />
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            สิทธิประโยชน์
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            CATHAY BUSINESS
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            AVIS PREFERRED
          </Link>
        </div>
        <div className="d-flex d-grid gap-3">
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            ติดต่อเรา
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            การจองของฉัน
          </Link>
        </div>
      </section>
      {/* ข้อความขอบคุณ */}
      <section id="thank-you-note" className="mt-4">
        <div className="d-flex flex-column w-100 align-items-center text-center grid gap-3">
          <CiCircleCheck style={{ fontSize: "100px", color: "#4fdb6d" }} />
          <div
            style={{ color: "#4fdb6d", fontWeight: "bolder" }}
            className="fs-2"
          >
            ขอบคุณ
          </div>
          <div className="fs-5">
            การจองของคุณเสร็จสมบูรณ์ เราจะส่งอีเมลยืนยันให้คุณในไม่ช้า
          </div>
          <div className="fs-5">
            หมายเลขอ้างอิงของคุณ: {localStorage.getItem("bookingNumber")}
          </div>
          <div style={{ fontSize: "18px" }}>
            โปรดอ่าน{" "}
            <Link
              to={process.env.PUBLIC_URL + `/`}
              style={{
                textDecoration: "underline",
                color: "red",
              }}
            >
              ข้อกำหนดและเงื่อนไข
            </Link>
          </div>
        </div>
      </section>
      {/* ข้อมูลรถ */}
      <section id="car-information" className="mt-4">
        <div
          style={{ backgroundColor: "#ebe8e8" }}
          className="d-flex flex-column grid"
        >
          <div className="d-flex justify-content-between">
            <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
              การจองของคุณ
            </div>
            <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
              วันที่จอง: {format(requestTime, "dd MMMM yyyy HH:mm")}
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid #d1d1d1",
              borderBottom: "1px solid #d1d1d1",
            }}
          >
            <div className="px-4">
              <div className="row">
                <div
                  className="col-sm py-4"
                  style={{ borderRight: "1px solid #d1d1d1" }}
                >
                  <div className="text-danger">เมื่อและที่ไหน</div>
                  <p>
                    {format(new Date(pickup_date), "dd MMM yyyy")} {pickup_time}{" "}
                    - {format(new Date(dropoff_date), "dd MMM yyyy")}{" "}
                    {dropoff_time}
                  </p>
                  <p>{location}</p>
                </div>
                <div
                  className="col-sm py-4"
                  style={{ borderRight: "1px solid #d1d1d1" }}
                >
                  <div className="row mt-2">
                    <div className="col-sm-12">
                      <p>
                        <h5 className="text-dark">{make ?? ""}</h5>
                      </p>
                      <p>
                        {make ?? ""} {model ?? ""}
                      </p>
                      <p>{transmission ?? ""}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-center">
                    <img
                      alt={image}
                      src={image ?? ""}
                      className="img-fluid"
                      width={200}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end p-4">
            <div style={{ marginRight: "250px" }}>รวมทั้งหมด</div>
            <div>
              IDR{" "}
              {Number(localStorage.getItem("totalIdr")).toLocaleString("id")}
            </div>
          </div>
        </div>
      </section>
      {/* Information */}
      <section id="information" className="mt-4">
        <div className="px-4">
          <div className="row">
            <div className="col-sm d-flex flex-column grid gap-3">
              <div className="fs-5" style={{ fontWeight: "bold" }}>
                เมื่อคุณรับรถของคุณ
              </div>
              <div>
                หนึ่งในสายบัตรของคุณต้องเป็นชื่อของคนขับหลัก
                เว้นแต่จะจองด้วยบัตรเครดิตของบริษัทที่เรียกเก็บเงินแบบรวมศูนย์
                หรือบัญชี และคุณต้องแสดงสิ่งต่อไปนี้ที่เคาน์เตอร์:
              </div>
              <div className="d-flex grid gap-3 align-items-center">
                <FaCheck color="red" />
                <div>ใบขับขี่ที่ถูกต้อง</div>
              </div>
              <div className="d-flex grid gap-3 align-items-center">
                <FaCheck color="red" />
                <div>บัตรชำระเงิน 1 ใบ</div>
              </div>
            </div>
            <div className="col-sm d-flex flex-column grid gap-3">
              <div className="fs-5">การจองของคุณรวมถึง:</div>
              <div className="fs-5">
                (การรวมอัตรานี้ขึ้นอยู่กับข้อตกลง/อัตราที่คุณยอมรับ)
              </div>
              <div className="d-flex">
                <div
                  className="d-flex flex-column grid gap-3"
                  style={{ marginRight: "150px" }}
                >
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>ค่าบริการสนามบิน</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>ภาษีท้องถิ่น</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>ฟรีกระเป๋ารถ</div>
                  </div>
                </div>
                <div className="d-flex flex-column grid gap-3">
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>คุ้มครองการโจรกรรม</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>คุ้มครองความเสียหาย</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>ไม่จำกัด</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ข้อมูลการเช่า */}
      <section id="rental-information" className="my-4">
        <div style={{ backgroundColor: "#ebe8e8" }} className="p-4">
          <div className="fs-5" style={{ fontWeight: "bold" }}>
            วิธีเดินทางไปยังสถานีเช่าของคุณ
          </div>
          <div class="mt-4">
            <div class="row">
              <div class="col-sm grid gap-2 d-flex flex-column">
                <div style={{ color: "rgb(212, 0, 42)" }}>รับรถ</div>
                <p>
                  <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                  <br />
                  {localStorage.getItem("cityLocation") ?? ""},{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                  <br />
                  {localStorage.getItem("addressLine1Location") ?? ""},{" "}
                  {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                  {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                  {localStorage.getItem("countryCodePick") ?? ""}
                  <br />
                  <b>รหัสสถานที่: </b>{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                </p>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaPhoneAlt color="red" />
                  <div>
                    tel: {localStorage.getItem("telephoneLocation") ?? ""}
                  </div>
                </div>
                {pickupLat && pickupLng && (
                  <Link
                    onClick={getPickUpDirectionUrl}
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    รับเส้นทาง
                  </Link>
                )}

                {!pickupLat && !pickupLng && (
                  <div className="mt-3 text-danger">
                    ขออภัย เราไม่สามารถค้นหาเส้นทางนำทางการรับรถ
                  </div>
                )}
                {pickupLat && pickupLng && (
                  <div className="mt-3">
                    <MapContainer
                      center={[pickupLat, pickupLng]}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{ height: "350px" }}
                      className="w-100"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customIcon}
                        position={[pickupLat, pickupLng]}
                      >
                        <Popup>
                          <p>
                            <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                            <br />
                            {localStorage.getItem("cityLocation") ?? ""},{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                            <br />
                            {localStorage.getItem("addressLine1Location") ?? ""}
                            ,{" "}
                            {localStorage.getItem("addressLine2Location") ?? ""}
                            , {localStorage.getItem("postalCodeLocation") ?? ""}
                            , {localStorage.getItem("countryCodePick") ?? ""}
                            <br />
                            <b>
                              {localStorage.getItem("telephoneLocation") ?? ""}
                            </b>
                            <br />
                            <b>รหัสสถานที่: </b>{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                          </p>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )}
              </div>
              <div class="col-sm grid gap-2 d-flex flex-column">
                <div style={{ color: "rgb(212, 0, 42)" }}>คืนรถ</div>
                <p>
                  <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                  <br />
                  {localStorage.getItem("cityLocation") ?? ""},{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                  <br />
                  {localStorage.getItem("addressLine1Location") ?? ""},{" "}
                  {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                  {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                  {localStorage.getItem("countryCodePick") ?? ""}
                  <br />
                  <b>รหัสสถานที่: </b>{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                </p>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaPhoneAlt color="red" />
                  <div>
                    tel: {localStorage.getItem("telephoneLocation") ?? ""}
                  </div>
                </div>
                {dropoffLat && dropoffLng && (
                  <Link
                    onClick={getDropOffDirectionUrl}
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    รับเส้นทาง
                  </Link>
                )}

                {!dropoffLat && !dropoffLng && (
                  <div className="mt-3 text-danger">
                    ขออภัย เราไม่สามารถค้นหาเส้นทางนำทางการคืนรถ
                  </div>
                )}
                {dropoffLat && dropoffLng && (
                  <div className="mt-3">
                    <MapContainer
                      center={[dropoffLat, dropoffLng]}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{ height: "350px" }}
                      className="w-100"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customIcon}
                        position={[dropoffLat, dropoffLng]}
                      >
                        <Popup>
                          <p>
                            <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                            <br />
                            {localStorage.getItem("cityLocation") ?? ""},{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                            <br />
                            {localStorage.getItem("addressLine1Location") ?? ""}
                            ,{" "}
                            {localStorage.getItem("addressLine2Location") ?? ""}
                            , {localStorage.getItem("postalCodeLocation") ?? ""}
                            , {localStorage.getItem("countryCodePick") ?? ""}
                            <br />
                            <b>
                              {localStorage.getItem("telephoneLocation") ?? ""}
                            </b>
                            <br />
                            <b>รหัสสถานที่: </b>{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                          </p>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />,
      {/* <div className="container my-5">
        <div className="card p-3">
          <h2 className="text-center">Booking Successfully</h2>
          <br />
          <h4 className="text-center">
            Here is your booking number confirmation
          </h4>
          <br />
          <div className="d-flex justify-content-center">
            <h2
              className="text-center border border-dark border-2"
              style={{ width: "250px" }}
            >
              {confirm_number}
            </h2>
          </div>

          <br />
          <div className="row justify-content-center mt-3">
            <div className="col-md-4">
              <p>
                <strong>First Name:</strong> {first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {last_name}
              </p>
              <p>
                <strong>Telephone:</strong> {telephone}
              </p>
              <p>
                <strong>Email:</strong> {email}
              </p>
              <p>
                <strong>Age:</strong> {age}
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <strong>Pickup Date:</strong> {pickup_date}
              </p>
              <p>
                <strong>Pickup Location:</strong> {pickup_location}
              </p>
              <p>
                <strong>Dropoff Date:</strong> {dropoff_date}
              </p>
              <p>
                <strong>Dropoff Location:</strong> {dropoff_location}
              </p>
            </div>
          </div>
          <br />
          <center>
            <a
              href={
                localStorage.getItem("token") != null
                  ? "/bookinglist"
                  : "/register"
              }
              className="btn btn-danger"
            >
              My Booking
            </a>
          </center>
        </div>
      </div> */}
    </div>
  );
};

export default ConfirmBooking;
