import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import SignModal from "./SignModal";
import ModalSubscribe from "./ModalSubscribe";

const Nav = (props) => {
  const [btnMobile, setBtnMobile] = useState(false);

  // useEffect(() => {
  //   props.setIsMenu(btnMobile);
  // }, [btnMobile]);
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <div className="main-menu__inner">
        <MobileMenu btnIsMobile={btnMobile} setIsMobile={setBtnMobile} />
        <ul className="main-menu__list myNav">
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>การจอง</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/makereservation`}>
                  สำรองห้องพัก
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  ดู / ยกเลิก
                </Link>
              </li>
              <li>
                <a
                  href="https://www.avis.com/en/reservation/get-e-receipt"
                  target="_blank"
                  rel="noreferrer"
                >
                  รับ E-ใบเสร็จรับเงิน
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>กิจกรรมและรายการพิเศษ</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/promotion`}>
                  การส่งเสริม
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>สถานที่</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/findlocation`}>
                  ค้นหาสถานที่
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/airportlocation`}>
                  ที่ตั้งสนามบินยอดนิยม
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>รถยนต์และบริการ</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/carguides`}>คู่มือรถ</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/productservice`}>
                  สินค้าและบริการ
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/protection`}>
                  ความคุ้มครองและความคุ้มครอง
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/program`}>โปรแกรม</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>ช่วย</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/customer-service`}>
                  บริการลูกค้า
                </Link>
              </li>
              <li>
                <a
                  href="https://faq.avis.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  คำถามที่พบบ่อย
                </a>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/contact-us`}>
                  ติดต่อเรา
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  การจอง/การยกเลิก
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.avis.co.uk/TermsAndCondition"
                  rel="noreferrer"
                >
                  ข้อกำหนดและเงื่อนไขสถานที่เช่า
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown ps-2 border-start">
            <a
              href="https://member.avisworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              AVIS ที่ต้องการ
            </a>
          </li>
          {localStorage.getItem("token") ? (
            <li className="dropdown">
              <a href={publicUrl + "userhome"}>
                <span className="fa fa-user"></span>{" "}
                {localStorage.getItem("name")}
              </a>
            </li>
          ) : (
            <>
              <li className="dropdown">
                <Link to={process.env.PUBLIC_URL + `/register`}>ลงทะเบียน</Link>
              </li>
              <li className="dropdown pe-2 border-end">
                <Link to={process.env.PUBLIC_URL + `#`}>
                  <SignModal />
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Nav;
