import React from "react";
import { Link } from "react-router-dom";

export default class CG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBg = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/car-guide1.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myText = {
      color: "#000",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
    };
    return (
      <>
        {/* Start Slogan One */}
        <section className="container-fluid mt-5" style={myBg}>
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
              <h1 className="ms-5 ps-4 text-up text-white">คู่มือรถเอวิส</h1>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-3">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    แสดง:
                  </span>
                  <select className="form-control">
                    <option>รถยนต์ทั้งหมด</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon2">
                    ดูรถใน:
                  </span>
                  <select className="form-control">
                    <option>สหรัฐอเมริกา</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "rgb(210, 210, 210)" }}>
          <div className="container">
            <div className="row pt-3 pb-3">
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>มินิแวน</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica หรือคล้ายกัน
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button className="link-avis">ข้อมูลรถ</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button className="btn btn-avis align-self-center">
                          จอง
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>มินิแวน</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica หรือคล้ายกัน
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button className="link-avis">ข้อมูลรถ</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button className="btn btn-avis align-self-center">
                          จอง
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>มินิแวน</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica หรือคล้ายกัน
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button className="link-avis">ข้อมูลรถ</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button className="btn btn-avis align-self-center">
                          จอง
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <p
                  className="mb-3 text-up text-black"
                  style={{ fontSize: "25px" }}
                >
                  ตัวเลือกการเช่ารถ Avis
                </p>
                <p className="mb-3 text-avis">
                  ไม่ว่าคุณจะมีสถานการณ์ใด คุณสามารถหาทางเลือกการเช่ารถที่
                  มีราคาไม่แพงที่ Avis ได้ รถยนต์ซีดานมักจะเป็นตัวเลือกที่ดี
                  โดยเฉพาะอย่างยิ่งสำหรับการประหยัดน้ำมัน แต่บางครั้งคุณก็ต้อง
                  การจัดเก็บห้องว่าง ความสะดวกสบาย การนั่ง หรือพลังงาน—และรายการ
                  ก็ยังคงดำเนินต่อไป คุณสามารถเรียกดูประเภทของรถเช่า เช่น:
                </p>
                <ol className="ps-3 mb-3 text-avis">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/suv-rental`}
                      className="link-avis"
                    >
                      SUVs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/van-rental`}
                      className="link-avis"
                    >
                      Vans
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/pickup-truck-rent`}
                      className="link-avis"
                    >
                      รถกระบะ
                    </Link>
                  </li>
                </ol>
                <p className="mb-3 text-avis">
                  เลือกจากประเภทของรถเช่าที่หลากหลายด้วยความมั่นใจ โดยรู้ว่า
                  Avis จะเพิ่มรถยนต์ใหม่เข้าสู่ฟลีทอย่างสม่ำเสมอ
                  และบำรุงรักษารถยนต์ทุกคันอย่างพิถีพิถัน Avis
                  มีรถเช่าหลายประเภท คุณสามารถเรียกดูรุ่นรถ Chevrolet
                  เพื่อดูตัวเลือกการเช่าที่น่าตื่นเต้นมากมาย ที่มีให้คุณ
                  ค้นพบรถยนต์ที่ดีที่สุดในรถเช่าหรูของเรา
                  ซึ่งมักมีตัวเลือกพรีเมี่ยมเช่นเบาะนั่งที่มีความร้อน
                  และระบบสารสนเทศที่ล้ำสมัย รถยนต์ที่มีอยู่จากรายการเช่าของเรา
                  จะแตกต่างกันไปตามสถานที่และวันที่ทำการจอง
                  แต่ยิ่งคุณจองเร็วเท่าไหร่ตัวเลือกก็จะยิ่งมากขึ้นเท่านั้น
                  ค้นพบรถยนต์ที่มีอยู่ในพื้นที่ของคุณโดยเพิ่มปลายทาง วันที่รับ
                  และวันที่ส่งคืนของคุณ เรียกดูและจองรถเช่ากับ Avis ออนไลน์
                  วันนี้
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Slogan One */}
      </>
    );
  }
}
