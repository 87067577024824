import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../../core/constant.js';
import { getToken } from '../../core/functions.js';

function BLN(props) {
    const [isNoPaid, setIsNoPaid] = useState(true);
    const navigate = useNavigate();

    const formatDate = (va) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const date = new Date(va);
        return date.toLocaleDateString(undefined, options);
    }

    const isCurrenDateBiggerThanPickupDate = (pickupDate) => {
        const currentDate = new Date();
        const date = new Date(pickupDate);
        return currentDate < date;
    }

    const handelCancel = (transaction) => {
        Axios.post(
            `${API_URL}/api/booking/cancel`,
            {
                book: transaction.rent_id,
                emailForm: transaction.emailForm
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            },
        ).then((res) => {
            alert('Booking berhasil dibatalkan');
            navigate('/bookinglist');
        }).catch(function (error) {
            // handle error
            console.log(error);
            alert('Booking gagal dibatalkan');
        })
    }

    const showBooking = () => {
        return props.transactions.length > 0 ? props.transactions.map((transaction, index) => (
            <div className='row' key={index}>
                <div className='col-sm-12 pt-3 pb-2'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row justify-content-between'>
                                <div className='col-sm-2 align-self-center'>
                                    <img src={transaction?.rent_detail?.car_img} alt='Vehicle' width={250} />
                                </div>
                                <div className='col-sm-6'>
                                    <h5><strong>Kode Booking: {transaction.status_book === 'Hold' ? '-' : transaction.booking_code}</strong></h5>
                                    <div className='row mt-3 justify-content-start'>
                                        <div className='col-sm-6'>
                                            <strong>Model Kendaraan:</strong> {transaction?.rent_detail?.car_model}<br />
                                            <strong>Nama Kendaraan:</strong> {transaction?.rent_detail?.car_name}<br />
                                            <strong>Status Booking:</strong> <span className={transaction?.status_book == 'Hold' ? 'text-danger' : 'text-success'}>{transaction?.status_book}</span><br />
                                        </div>
                                        <div className='col-sm-6'>
                                            <strong>Tanggal Booking:</strong> {formatDate(transaction?.pickup_date)} {transaction?.pickup_time}<br />
                                            <strong>Tanggal Pengembalian:</strong> {formatDate(transaction?.return_date)} {transaction?.return_time}<br />

                                            <strong>Pickup Location: </strong> {transaction?.pickup_loc}<br />
                                            <strong>Dropoff Location: </strong>{transaction?.return_loc}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2 d-flex align-items-start flex-row'>
                                    {transaction.status_book == 'Hold' ?
                                        <a href={transaction?.transaction?.link_midtrans} target='_blank' className='btn btn-warning me-3'>Bayar</a>
                                        : <div></div>}
                                    {transaction.status_book == 'Cancel' ? <di></di> :
                                        isCurrenDateBiggerThanPickupDate(transaction.pickup_date) ? <button onClick={() => {
                                            window.confirm('Apakah anda yakin akan membatalkan booking ini?') && handelCancel(transaction)
                                        }} className='btn btn-danger'>Cancel</button> : <di></di>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )) : <div className='row'>
            <div className='col-sm-12 pt-3 pb-2'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 text-center'>
                                <h5>Belum ada booking</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <section>
                <div className='container'>
                    <div className='row mt-3 pb-3'>
                        <div className='col-sm-12'>
                            <h4>Booking List</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ backgroundColor: "#eaeaea" }}>
                <div className='container'>
                    {showBooking()}
                </div>
            </section>
        </>
    )
}

function BL(props) {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        Axios.post(
            `${API_URL}/api/booking/list/avis`,
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            },
        ).then((res) => {
            setTransactions(res.data.booking);
        });
    }, [])

    return <BLN {...props} navigate={navigate} transactions={transactions} />
}

export default BL;
