import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default class FooterOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        {/* Start Footer One */}
        <footer className="footer-one">
          <div className="footer-one__top">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__top-inner">
                    <div className="row">
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            พันธมิตรคะแนนไมล์
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/program`}>
                                กาตาร์แอร์เวย์
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/program`}>
                                สายการบินสิงคโปร์
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/program`}>
                                มาสเตอร์การ์ด
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/program`}>
                                บัตรวีซ่า
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">ข้อมูลบริษัท</h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/about-avis`}>
                                เกี่ยวกับ เอวิส
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link to={`${process.env.PUBLIC_URL}/sitemap`}>
                                แผนผังเว็บไซต์
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="footer-widget__column footer-widget__services">
                          <h2 className="footer-widget__title">
                            ความปลอดภัย &amp; ความเป็นส่วนตัว
                          </h2>
                          <ul className="footer-widget__services-list">
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/terms-of-use`}
                              >
                                เงื่อนไขการใช้งาน
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/privacy-notice`}
                              >
                                ประกาศความเป็นส่วนตัว
                              </Link>
                            </li>
                            <li className="footer-widget__services-list-item">
                              <Link
                                to={`${process.env.PUBLIC_URL}/copyright-notice`}
                              >
                                ประกาศเกี่ยวกับลิขสิทธิ์
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start Footer One Bottom */}
          <div
            className="footer-one__bottom clearfix"
            style={{ backgroundColor: "#3a3a3a" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="footer-one__bottom-inner text-center">
                    <div className="footer-one__bottom-text">
                      <p>
                        ลิขสิทธิ์ © 2022{" "}
                        <Link to={process.env.PUBLIC_URL + `/`}>Avis.</Link>{" "}
                        สงวนลิขสิทธิ์ทุกประการ
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* End Footer One Bottom */}
        </footer>
        {/* End Footer One */}
      </>
    );
  }
}
