import React from "react";

export default class DP extends React.Component {
  render() {
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Cars & Services</li>
                    <li className="breadcrumb-item">Protection & Coverages</li>
                    <li className="breadcrumb-item">USA & Canada</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      {this.props.data}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5">
            <div className="row">
              {this.props.data === "Extended Roadside Assistance" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    ขยายเวลาช่วยเหลือฉุกเฉินบนท้องถนน
                  </h1>
                  <p className="text-avis mt-2">
                    <b>
                      วางแผนล่วงหน้าเพื่อความสบายใจ
                      ด้วยบริการช่วยเหลือบนถนนแบบขยายเวลา
                      คุณสามารถเดินทางได้อย่างมั่นใจ
                      เราเข้าใจว่าอาจมีสิ่งที่ไม่คาดคิดเกิดขึ้น
                      และเราพร้อมดูแลคุณด้วยบริการช่วยเหลือบนท้องถนน ซึ่งรวมถึง:
                    </b>
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • <b>บริการช่วยปลดล็อค</b> - เข้าถึงรถหากกุญแจถูกล็อคในรถ
                      หรือขอรับกุญแจสำรองหรอการช่วยเหลือจากช่างทำกุญแจ
                    </li>
                    <li>
                      • <b>บริการเปลี่ยนและซ่อมยางแบน</b> -
                      เราจะเปลี่ยนยางแบนหรือยางที่เสียหายด้วยยางสำรอง
                      หรือพารถไปยังศูนย์บริการที่ใกล้ที่สุด
                    </li>
                    <li>
                      • <b>บริการสตาร์ทเครื่องยนต์</b> -
                      หากแบตเตอรี่ของรถมีปัญหา เราจะช่วยสตาร์ทรถให้คุณ
                    </li>
                    <li>
                      • <b>บริการเติมน้ำมัน</b> - หากคุณน้ำมันหมด
                      เราจะเติมน้ำมันให้คุณ 3 แกลลอน (ใน US/CA)
                      หรือเติมน้ำมันมูลค่าไม่เกิน $10 (ใน AU/NZ)
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    <b>
                      เพื่อประสบการณ์การขับขี่ที่ปลอดภัยที่สุด
                      โปรดขอใช้บริการช่วยเหลือบนถนนแบบขยายเวลาตอนที่คุณรับรถที่สถานีของเรา
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    ในกรณีที่มีปัญหาหรือเหตุฉุกเฉินที่ไม่คาดคิด
                    คุณจะต้องการบริการที่รวดเร็วและเชื่อถือได้
                    ซึ่งเป็นประโยชน์อย่างยิ่งเมื่อคุณอยู่ในเมืองที่ไม่คุ้นเคย
                    ด้วยบริการช่วยเหลือบนถนนแบบขยายเวลา
                    คุณจะได้รับความช่วยเหลือฉุกเฉินที่รวดเร็วและเชื่อถือได้
                  </p>
                  <p className="text-avis mt-2">
                    เพื่อเข้าถึงบริการช่วยเหลือบนถนนแบบขยายเวลา 24 ชั่วโมง 7
                    วันต่อสัปดาห์ โปรดโทร:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>• ในสหรัฐอเมริกา/แคนาดา โทร 800-354-2847</li>
                    <li>• ในออสเตรเลีย โทร 1800 063 973</li>
                    <li>• ในนิวซีแลนด์ โทร 0800 652 847</li>
                  </ul>
                  <p className="text-avis mt-2">
                    บริการช่วยเหลือบนถนนแบบขยายเวลาสามารถซื้อได้ที่เคาน์เตอร์เมื่อคุณรับรถ
                  </p>
                </div>
              ) : this.props.data === "Loss Damage Waiver (LDW)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    การสละสิทธิ์ความเสียหายจากการสูญหาย (LDW)
                  </h1>
                  <p className="text-avis mt-2">
                    การสละสิทธิ์ความเสียหายจากการสูญหาย (“<b>LDW</b>”)
                    ไม่ใช่การประกันภัยและไม่บังคับ หากคุณยอมรับ LDW
                    แบบเต็มโดยการลงชื่อย่อของคุณในเอกสารเช่ารถในอัตรารายวันเพิ่มเติม
                    สำหรับแต่ละวันเต็มหรือบางส่วนที่รถเช่าอยู่กับคุณและรถได้รับการดำเนินการตามข้อตกลง
                    เราจะรับผิดชอบต่อการสูญหายหรือความเสียหายของรถ
                    ยกเว้นในกรณีที่กฎหมายอนุญาต
                    สำหรับกุญแจหรืออุปกรณ์เปิดประตูที่หายไป เสียหาย หรือถูกขโมย
                    บริการลากจูงหรือซ่อมแซมยาง เว้นแต่ว่าเกี่ยวข้องกับอุบัติเหตุ
                    หรือการกู้คืนรถที่ถูกขโมย (ยกเว้นในรัฐอลาสกา)
                    และยกเว้นจำนวนความรับผิดชอบของคุณ ซึ่งระบุไว้ในเอกสารเช่า
                  </p>
                  <p className="text-avis mt-2">
                    การสละสิทธิ์ความเสียหายจากการสูญหายบางส่วน (“<b>PDW</b>”)
                    สามารถใช้ได้เฉพาะที่กฎหมายอนุญาตเท่านั้น หากคุณยอมรับ PDW
                    ในอัตรารายวันที่ระบุ และรถได้รับการดำเนินการตามข้อตกลง
                    เราจะรับผิดชอบต่อการสูญหายหรือความเสียหายของรถจนถึงจำนวนที่ระบุในเอกสารเช่า
                    และคุณต้องรับผิดชอบต่อความสูญหายหรือความเสียหายอื่น ๆ
                    ทั้งหมด หากคุณไม่ยอมรับ LDW หรือ PDW
                    คุณจะต้องรับผิดชอบต่อการสูญหายหรือความเสียหายของรถ
                  </p>
                  <p className="text-avis mt-2">
                    ในประเทศแคนาดา หากคุณยอมรับ LDW
                    อาจยังมีจำนวนเงินที่ไม่สามารถสละสิทธิ์ได้ซึ่งคุณจะต้องรับผิดชอบในกรณีที่มีการสูญหายหรือความเสียหายต่อรถ
                    จำนวนนี้จะถูกระบุไว้ในเอกสารเช่าในขณะที่คุณเช่า
                    ปัจจุบันจำนวนนี้สูงสุดคือ CA $500
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      ประกาศเกี่ยวกับการสละสิทธิ์ความเสียหายจากการสูญหาย (LDW)
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    ส่วนต่อไปนี้เป็นไปตามข้อกำหนดของบางรัฐในการเปิดเผยข้อมูล
                  </p>
                  <p className="text-avis mt-2">
                    <b>คอนเนตทิคัต:</b>{" "}
                    หากต้องการคำนวณค่าธรรมเนียมประจำปีสำหรับการสละสิทธิ์ความเสียหายจากการสูญหาย
                    (LDW หรือ PDW)
                    ให้คูณอัตรารายวันที่ระบุในหน้าแรกของเอกสารเช่ารถด้วย 365 วัน
                    การสละสิทธิ์ความเสียหายจากการสูญหายครอบคลุมความสูญหายหรือความเสียหายจากการโจรกรรม
                    การชนกัน การทำลายทรัพย์สิน หรือสาเหตุอื่น ๆ
                    ไม่ว่าคุณจะยอมรับการสละสิทธิ์ความเสียหายจากการสูญหายหรือไม่
                    หรือหากการสละสิทธิ์ความเสียหายจากการสูญหายไม่ได้รับอนุญาต
                    คุณจะต้องรับผิดชอบต่อการสูญหายหรือความเสียหายที่เกิดจากคุณหรือคนขับที่ได้รับอนุญาต
                  </p>
                  <p className="text-avis mt-2">
                    คุณควรตรวจสอบนโยบายประกันรถยนต์ส่วนตัวของคุณหรือบัตรเครดิตของคุณ
                    เพราะอาจครอบคลุมความสูญหายหรือความเสียหาย
                    และการบาดเจ็บส่วนบุคคลที่เกิดขึ้นขณะใช้หรือขับรถเช่า
                    หากความรับผิดชอบของคุณต่อความสูญหายหรือความเสียหายได้รับการคุ้มครองโดยประกันของคุณเอง
                    คุณจะต้องอนุญาตให้ Avis ติดต่อกับผู้ให้บริการประกันของคุณ
                    Avis จะคืนเงินให้หากเรียกเก็บเงินเกินกว่าค่าซ่อมแซม
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      แคลิฟอร์เนีย ไอโอวา อินดีแอนา หลุยเซียนา ฮาวาย
                      แมสซาชูเซตส์ มินนิโซตา นิวยอร์ก เนวาดา โรดไอแลนด์
                      และเท็กซัส:
                    </b>{" "}
                    กฎหมายของรัฐเหล่านี้กำหนดให้กรมธรรม์ประกันภัยรถยนต์ส่วนตัวต้องครอบคลุมการเช่ารถคันนี้
                    คุณควรตรวจสอบกรมธรรม์ประกันภัยรถยนต์ส่วนตัวของคุณเพื่อดูว่าการซื้อ
                    LDW จะซ้ำซ้อนกับความคุ้มครองที่กรมธรรม์ของคุณมีหรือไม่
                  </p>
                  <p className="text-avis mt-2">
                    <b>วิสคอนซิน:</b>
                  </p>
                  <p className="text-avis mt-2">
                    ข้อกำหนดเกี่ยวกับความรับผิดชอบต่อความเสียหายของรถเช่าในรัฐวิสคอนซิน
                    ระบุว่าในการเช่าในรัฐวิสคอนซิน ข้อตกลงทั้งหมดจะมีผลบังคับ
                    ยกเว้นกรณีที่มีความขัดแย้งระหว่างเงื่อนไขเฉพาะของรัฐวิสคอนซินกับข้อตกลงอื่น
                    ๆ เงื่อนไขเฉพาะของวิสคอนซินจะมีผลเหนือกว่า
                  </p>
                  <p className="text-avis mt-2">
                    แจ้งสิทธิ์ในการตรวจสอบความเสียหาย
                  </p>
                  <p className="text-avis mt-2">
                    หากรถได้รับความเสียหาย เราไม่สามารถเรียกเก็บค่าเสียหายได้
                    เว้นแต่คุณหรือผู้ขับขี่ที่ได้รับอนุญาตที่เราเรียกร้องความรับผิดชอบจะได้รับแจ้งสิทธิ์ของคุณและบริษัทประกันในการตรวจสอบรถภายในสองวันทำการหลังจากที่เราได้รับแจ้งเกี่ยวกับความเสียหาย
                    หากคุณขอ เราต้องให้สำเนาประมาณการการซ่อมจากอู่ซ่อมรถภายใน 2
                    วันทำการหลังจากได้รับการประมาณการ
                  </p>
                  <p className="text-avis mt-2">
                    <b>ข้อร้องเรียน:</b>
                  </p>
                  <p className="text-avis mt-2">
                    หากคุณมีข้อร้องเรียนเกี่ยวกับการพยายามเรียกเก็บค่าเสียหายหรือสำเนากฎหมายของรัฐที่กำหนดสิทธิและภาระหน้าที่ของคุณ
                    โปรดติดต่อ:
                  </p>
                  <p className="text-avis mt-2">
                    Wisconsin Consumer Protection Bureau, P.O. Box 8911,
                    Madison, WI 53708-8911 โทร 608-224-4960 (ในพื้นที่ Madison)
                    หรือโทรฟรี 1-800-422-7128
                  </p>
                </div>
              ) : this.props.data === "Additional Liability Insurance (ALI)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    ประกันความรับผิดเพิ่มเติม (ALI)
                  </h1>
                  <p className="text-avis mt-2">
                    หากคุณเลือกซื้อ ALI
                    ความคุ้มครองจะถูกมอบให้แก่คุณและผู้ขับขี่ที่ได้รับอนุญาตภายใต้กรมธรรม์รถยนต์ส่วนเกินที่ออกให้กับ
                    Avis ALI
                    ให้การป้องกันสำหรับการเรียกร้องความรับผิดทางรถยนต์จากบุคคลภายนอกในส่วนที่เกินจากขีดจำกัดความรับผิดชอบทางการเงินขั้นต่ำที่กำหนดไว้ภายใต้ย่อหน้า
                    22 ข้างต้น และมีขีดจำกัดสูงสุดรวมที่ 1,000,000 ดอลลาร์หรือ
                    2,000,000 ดอลลาร์ ขึ้นอยู่กับเขตอำนาจศาลที่เช่ารถ
                    เพื่อการบาดเจ็บทางร่างกาย การเสียชีวิต
                    หรือความเสียหายต่อทรัพย์สินสำหรับแต่ละอุบัติเหตุ
                    ความคุ้มครองนี้ให้ไว้ภายใต้กรมธรรม์ประกันความรับผิดส่วนเกิน
                    ซึ่งอธิบายไว้อย่างละเอียดในโบรชัวร์ที่มีอยู่
                    และอยู่ภายใต้เงื่อนไขและข้อจำกัดทั้งหมดที่อธิบายไว้ในย่อหน้า
                    22 ข้างต้น
                    ยกเว้นว่ากรมธรรม์จะควบคุมตามเงื่อนไขของข้อตกลงทุกครั้ง ALI
                    ไม่มีผลบังคับใช้กับความรับผิดสำหรับการบาดเจ็บทางร่างกายหรือความเสียหายต่อทรัพย์สินที่เกิดจากการ
                    "ใช้รถในทางที่ห้าม" ตามที่อธิบายไว้ในย่อหน้า 15 ของข้อตกลง
                    ซึ่งทั้งหมดเป็นข้อยกเว้นสำหรับ ALI ข้อยกเว้นอื่น ๆ ของ ALI
                    มีการระบุไว้ในกรมธรรม์ ALI
                    คุณเข้าใจว่าคุณจะถูกเรียกเก็บเงินในอัตรารายวันสำหรับวันเต็มแม้ว่าคุณจะไม่ได้ใช้รถตลอดทั้งวัน
                    การเปิดเผยข้อมูลเกี่ยวกับประกันความรับผิดเพิ่มเติมในรัฐฟลอริดา
                  </p>
                  <p className="text-avis mt-2">
                    <b>ประกันความรับผิดเพิ่มเติม (ALI) คืออะไร?</b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>Avis</b> มีประกันความรับผิดเพิ่มเติม (ALI)
                    ให้บริการที่สถานที่ทุกแห่งในรัฐฟลอริดา ALI
                    เป็นบริการพิเศษเสริมที่ Avis นำเสนอเมื่อคุณเช่ารถจาก Avis
                    เป็น "กรมธรรม์ประกันภัยความรับผิดส่วนเกินรถยนต์"
                    ที่ให้ความคุ้มครองเพิ่มเติมตามขีดจำกัดที่ระบุไว้
                    นอกเหนือจากขีดจำกัดที่กำหนดในข้อตกลง ALI
                    จะประกันคุณและผู้ดำเนินการที่ได้รับอนุญาตตามที่ระบุไว้ในข้อตกลงสำหรับการเรียกร้องจากบุคคลภายนอกต่อคุณ
                    ซึ่งเป็นลูกค้า
                    สำหรับการบาดเจ็บ/เสียชีวิตและความเสียหายต่อทรัพย์สินที่เกิดจากการใช้หรือการดำเนินการของรถเช่า
                    Avis ตามที่อนุญาตในข้อตกลง ALI
                    เป็นกรมธรรม์ประกันภัยแยกต่างหากที่ออกให้กับ Avis โดย ACE
                    American Insurance Company
                  </p>
                  <p className="text-avis mt-2">
                    หากคุณเลือกที่จะรับ ALI
                    คุณจะต้องจ่ายค่าธรรมเนียมรายวันที่เพิ่มเติมตามที่ระบุไว้ในข้อตกลง
                    <b>การซื้อ ALI ไม่จำเป็นต้องเช่ารถจาก Avis</b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>ขีดจำกัดความคุ้มครองที่ให้โดย ALI คืออะไร?</b>
                  </p>
                  <p className="text-avis mt-2">
                    ขีดจำกัดความคุ้มครองของ ALI
                    เท่ากับความแตกต่างระหว่างขีดจำกัดสูงสุดรวมของ ALI ที่
                    1,000,000 ดอลลาร์ (หรือขีดจำกัดสูงสุดรวม 2,000,000
                    ดอลลาร์ในเขตอำนาจศาลต่อไปนี้: แคลิฟอร์เนีย ฟลอริดา ฮาวาย
                    และนิวยอร์ก)
                    และขีดจำกัดการคุ้มครองความรับผิดที่ให้ไว้ภายใต้ข้อตกลงการเช่าของ
                    Avis
                    <b>
                      โปรดทราบว่า ในทุกรัฐ เราให้บริการขีดจำกัดสูงสุดรวม
                      1,000,000 ดอลลาร์ สำหรับรถตู้ที่บรรทุกผู้โดยสาร 10, 12 และ
                      15 คนเท่านั้น
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>ความคุ้มครองของ ALI มีผลบังคับใช้เมื่อใดและที่ใด?</b>
                  </p>
                  <p className="text-avis mt-2">
                    คุณและผู้ดำเนินการที่ได้รับอนุญาตจะได้รับการคุ้มครองในขณะที่ขับรถเช่าภายในสหรัฐอเมริกาและแคนาดา
                    แต่เฉพาะเมื่อรถถูกเช่าและส่งคืนในสหรัฐอเมริกาเท่านั้น
                    ความคุ้มครองไม่มีผลบังคับใช้ในเม็กซิโก
                  </p>
                  <p className="text-avis mt-2">
                    <b>ฉันจะรายงานการเรียกร้องอย่างไร?</b>
                  </p>
                  <p className="text-avis mt-2">
                    หากคุณประสบอุบัติเหตุ
                    คุณต้องกรอกแบบฟอร์มรายงานอุบัติเหตุและส่งมอบให้กับสถานที่เช่ารถของ
                    Avis ในการเรียกร้องหรือแจ้งการเรียกร้อง ส่งหนังสือแจ้งไปที่:
                  </p>
                  <p className="text-avis mt-2">Avis Rent A Car System, Inc.</p>
                  <p className="text-avis mt-2">Attn: Claims</p>
                  <p className="text-avis mt-2">P.O. Box 61247</p>
                  <p className="text-avis mt-2">Virginia Beach, VA 23466</p>
                  <p className="text-avis mt-2">1-866-446-8376</p>

                  <p className="text-avis mt-2">
                    <b>
                      ALI
                      ส่งผลต่อการใช้กรมธรรม์ประกันภัยรถยนต์หรือประกันภัยร่มของคุณอย่างไร?
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    กรมธรรม์ประกันภัยส่วนบุคคลของคุณที่ให้ความคุ้มครองรถยนต์ที่เป็นกรรมสิทธิ์
                    หรือกรมธรรม์ส่วนบุคคลอื่นๆ อาจให้ความคุ้มครองเพิ่มเติม
                    และในขอบเขตนั้น ALI อาจให้ความคุ้มครองซ้ำ
                    การตรวจสอบว่ากรมธรรม์ของคุณมีผลบังคับใช้เมื่อใดและอย่างไรสามารถตรวจสอบได้จากข้อกำหนดของกรมธรรม์เองเท่านั้น
                    เนื่องจากข้อกำหนดเหล่านี้มักจะแตกต่างกัน อย่างไรก็ตาม
                    การคุ้มครองที่ ALI มอบให้ (หาก ALI ถูกเลือกใช้)
                    เช่นเดียวกับขีดจำกัดการคุ้มครองของข้อตกลงการเช่าของ Avis
                    จะเป็นลำดับแรกก่อนกรมธรรม์ส่วนบุคคลของคุณ ซึ่งหมายความว่า
                    ก่อนที่กรมธรรม์ของคุณจะใช้ในการชำระเรียกร้อง ความคุ้มครอง
                    1,000,000 ดอลลาร์ (หรือความคุ้มครอง 2,000,000
                    ดอลลาร์ในเขตอำนาจศาลต่อไปนี้: แคลิฟอร์เนีย ฟลอริดา ฮาวาย
                    และนิวยอร์ก) ที่ ALI
                    และขีดจำกัดการคุ้มครองตามข้อตกลงการเช่าของ Avis
                    ต้องถูกใช้จนหมดก่อน
                    <b>
                      โปรดทราบว่า ในทุกรัฐ เราให้บริการขีดจำกัดสูงสุดรวม
                      1,000,000 ดอลลาร์ สำหรับรถตู้ที่บรรทุกผู้โดยสาร 10, 12 และ
                      15 คนเท่านั้น
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>มีข้อยกเว้นอะไรบ้างที่ใช้กับ ALI?</b>
                  </p>
                  <p className="text-avis mt-2">
                    ข้อกำหนดต่อไปนี้เน้นย้ำข้อยกเว้นบางประการที่จะทำให้ ALI
                    ไม่ครอบคลุม
                    สิ่งสำคัญคือคุณต้องอ่านเอกสารการเช่าและกรมธรรม์อย่างละเอียดสำหรับข้อยกเว้นทั้งหมด
                  </p>
                  <ul className="text-avis mt-2">
                    <li className="text-avis mt-2">
                      ไม่มีความคุ้มครองสำหรับความรับผิดที่เกิดจากการบาดเจ็บต่อร่างกายหรือทรัพย์สินที่เกิดจากการใช้รถเช่า
                      Avis ในทางที่ห้าม ซึ่งได้อธิบายไว้ในข้อตกลง
                    </li>
                    <li className="text-avis mt-2">
                      ALI ไม่ให้ความคุ้มครองใดๆ
                      สำหรับการเรียกร้องความรับผิดจากบุคคลภายนอกในเหตุการณ์หรืออุบัติเหตุที่เกิดขึ้นนอกรัฐของสหรัฐอเมริกา
                      หรือประเทศแคนาดา
                    </li>
                  </ul>
                </div>
              ) : this.props.data === "Personal Accident Insurance (PAI)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    ประกันอุบัติเหตุส่วนบุคคล (PAI)
                  </h1>
                  <p className="text-avis mt-2">
                    คุณจะต้องจ่ายสำหรับประกันอุบัติเหตุส่วนบุคคลและประกันภัยทรัพย์สิน
                    หากคุณยอมรับ
                    มันจะถูกเรียกเก็บค่าธรรมเนียมตามวันที่คุณเช่ารถแม้ว่าคุณจะไม่ได้ใช้รถตลอดทั้งวันก็ตาม
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      <u>PAI – ประกันอุบัติเหตุส่วนบุคคล (หากมี)</u>
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI จะให้ประโยชน์ด้านการแพทย์ โรงพยาบาล การเสียชีวิต
                    และการตัดอวัยวะสำหรับการบาดเจ็บทางร่างกายที่เกิดจากอุบัติเหตุ
                    การคุ้มครองจะมีตลอดระยะเวลาของสัญญาเช่าและจะไม่เกิน 30
                    วันติดต่อกัน
                  </p>
                  <p className="text-avis mt-2">
                    PAI
                    ให้ประโยชน์สูงสุดดังต่อไปนี้สำหรับการบาดเจ็บทางร่างกายจากอุบัติเหตุ:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • การเสียชีวิตและการตัดอวัยวะโดยอุบัติเหตุสูงสุด $175,000*
                      (ผู้เช่า) และ $25,000** (ผู้โดยสาร)
                    </li>
                    <li>• ค่าใช้จ่ายทางการแพทย์จากอุบัติเหตุสูงสุด $10,000</li>
                    <li>
                      • ประโยชน์ค่ารักษาในโรงพยาบาล*** $500 ต่อวันสูงสุด 30 วัน
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    *$200,000 ใน NY; $50,000 ใน NH
                  </p>
                  <p className="text-avis mt-2">
                    **$200,000 ใน NY; $175,000 ใน CT, IN, KS, MO, MT, VT, VA,
                    WA; $50,000 ใน NH
                  </p>
                  <p className="text-avis mt-2">
                    ***ประโยชน์ค่ารักษาในโรงพยาบาลไม่สามารถใช้ได้ใน NY
                  </p>
                  <p className="text-avis mt-2">
                    <b>PAI Plus</b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI Plus
                    ออกแบบมาเพื่อตอบสนองความต้องการของผู้เช่าที่ต้องการความคุ้มครองประกันภัยในจำนวนมากขึ้น
                    (เช่น ผู้เข้าชมจากประเทศที่ไม่ใช่สหรัฐอเมริกา)
                  </p>
                  <p className="text-avis mt-2">
                    ผู้เช่าจะได้รับการคุ้มครองตลอด 24
                    ชั่วโมงในระยะเวลาของสัญญาเช่าจนถึง 30 วัน
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • การเสียชีวิตและการตัดอวัยวะโดยอุบัติเหตุสูงสุด $250,000*
                    </li>
                    <li>
                      • ความคุ้มครองทางการแพทย์ $15,000
                      สำหรับการบาดเจ็บที่เกิดจากอุบัติเหตุ
                    </li>
                    <li>
                      • $500*** ต่อวันสูงสุด 30
                      วันสำหรับการรักษาในโรงพยาบาลเนื่องจากอุบัติเหตุ
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    ในรถยนต์ที่มีความจุสูงสุด 15 คน
                    ผู้โดยสารจะได้รับการคุ้มครองในขณะที่อยู่ในรถเป็นจำนวน:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • $125,000**
                      ต่อผู้โดยสารสำหรับการเสียชีวิตและการตัดอวัยวะโดยอุบัติเหตุ
                    </li>
                    <li>
                      • ความคุ้มครองทางการแพทย์ $15,000
                      สำหรับการบาดเจ็บที่เกิดจากอุบัติเหตุ
                    </li>
                    <li>
                      • $500*** ต่อวันสูงสุด 30
                      วันสำหรับการรักษาในโรงพยาบาลเนื่องจากอุบัติเหตุ
                    </li>
                    <li>• $250,000 ใน NY; $50,000 ใน NH</li>
                    <li>
                      • **$250,000 ใน NY; $250,000 ใน CT, IN, KS, MO, MT, VT,
                      VA, WA; $50,000 ใน NH
                    </li>
                    <li>
                      • ***ประโยชน์ค่ารักษาในโรงพยาบาลไม่สามารถใช้ได้ใน NY
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    สำหรับโบรชัวร์ฉบับเต็ม กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐทั่วไป กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-general-states.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับนิวยอร์ก กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับคอนเนตทิคัต, อินดีแอนา, แคนซัส,
                    มิสซูรี, มอนแทนา, เวอร์มอนต์, เวอร์จิเนีย, และวอชิงตัน กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่.
                    </a>
                  </p>
                </div>
              ) : this.props.data === "Personal Accident Insurance (PAI)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    {this.props.data}
                  </h1>
                  <p className="text-avis mt-2">
                    You’ll pay for Personal Accident and Effects Insurance if
                    you accept it. You understand that you will be charged the
                    rate per day for a full day even if you don’t have the car
                    the entire day.
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      <u>PAI – Personal Accident Insurance (where available)</u>
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI provides medical, hospital, death and dismemberment
                    benefits for bodily injury resulting from an accident.
                    Coverage is for the term of the rental agreement and will
                    not exceed a 30-day consecutive period.
                  </p>
                  <p className="text-avis mt-2">
                    PAI provides the following maximum benefits for accidental
                    bodily injury:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • Accidental Death and Dismemberment up to $175,000*
                      (renter) and $25,000** (passenger).
                    </li>
                    <li>• Accident Medical Expenses up to $10,000</li>
                    <li>
                      • Hospital Accident Benefit*** of $500 per day up to 30
                      days
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    *$200,000 in NY; $50,000 in NH
                  </p>
                  <p className="text-avis mt-2">
                    **$200,000 in NY; $175,000 in CT, IN, KS, MO, MT, VT, VA,
                    WA; $50,000 in NH
                  </p>
                  <p className="text-avis mt-2">
                    ***Hospital Accident Benefit not available in NY
                  </p>
                  <p className="text-avis mt-2">
                    <b>PAI Plus</b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI Plus is designed to meet the needs of renters who
                    require extended amounts of insurance coverage (i.e.
                    visitors from non-U.S. countries).
                  </p>
                  <p className="text-avis mt-2">
                    The renter is covered 24 hours a day during the term of the
                    rental agreement up to 30 days
                  </p>
                  <ul className="text-avis mt-2">
                    <li>• $250,000* accidental death and dismemberment</li>
                    <li>
                      • $15,000 medical coverage for injuries due to an accident
                    </li>
                    <li>
                      • $500*** a day up to 30 days for hospitalization due to
                      an accident
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    In vehicles with a capacity of up to 15 occupants, the
                    passengers are covered while in the car for:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • $125,000** per passenger for accidental death and
                      dismemberment
                    </li>
                    <li>
                      • $15,000 medical coverage for injuries due to accident
                    </li>
                    <li>
                      • $500*** a day up to 30 days for hospitalization due to
                      an accident
                    </li>
                    <li>• $250,000 in NY; $50,000 in NH</li>
                    <li>
                      • **$250,000 in NY; $250,000 in CT, IN, KS, MO, MT, VT,
                      VA, WA; $50,000 in NH
                    </li>
                    <li>• ***Hospital Accident Benefit not available in NY</li>
                  </ul>
                  <p className="text-avis mt-2">
                    For the full brochure, please{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      click here.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    For the policy certificate for General States, please{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-general-states.pdf"
                      target="_blank"
                    >
                      click here.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    For the policy certificate for New York, please{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      click here.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    For the policy certificates for Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, and
                    Washington, please{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      click here.
                    </a>
                  </p>
                </div>
              ) : this.props.data === "Personal Effect Insurance (PEP)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    การประกันภัยทรัพย์สินส่วนบุคคล (PEP)
                  </h1>
                  <p className="text-avis mt-2">
                    ESP
                    ซึ่งมีให้บริการเฉพาะผู้เช่าที่ไม่ใช่ผู้พำนักในสหรัฐอเมริกา
                    คุณจะต้องจ่ายค่า ESP หากคุณยอมรับ
                    โดยจะมีการเรียกเก็บค่าบริการเป็นรายวันสำหรับวันเต็ม
                    แม้ว่าคุณจะไม่ได้ใช้รถทั้งวันก็ตาม ESP
                    จะให้ความคุ้มครองสำหรับผู้เดินทางในกรณีที่เจ็บป่วยในช่วงระยะเวลาการเช่า
                    และยังคุ้มครองผู้โดยสารที่ไม่ใช่ผู้พำนักในสหรัฐฯ
                    ของผู้เช่าอีกด้วย
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับโบรชัวร์ฉบับเต็ม กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐทั่วไป กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-certificate-general-states.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐนิวยอร์ก กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐคอนเนตทิคัต, อินเดียนา, แคนซัส,
                    มิสซูรี, มอนแทนา, เวอร์มอนต์, เวอร์จิเนีย และ วอชิงตัน กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                </div>
              ) : (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    แผนประกันสุขภาพฉุกเฉิน (ESP)
                  </h1>
                  <p className="text-avis mt-2">
                    ESP
                    ซึ่งมีให้บริการเฉพาะผู้เช่าที่ไม่ใช่ผู้พำนักในสหรัฐอเมริกา
                    คุณจะต้องจ่ายค่า ESP หากคุณยอมรับ
                    โดยจะมีการเรียกเก็บค่าบริการเป็นรายวันสำหรับวันเต็ม
                    แม้ว่าคุณจะไม่ได้ใช้รถทั้งวันก็ตาม ESP
                    จะให้ความคุ้มครองสำหรับผู้เดินทางในกรณีที่เจ็บป่วยในช่วงระยะเวลาการเช่า
                    และยังคุ้มครองผู้โดยสารที่ไม่ใช่ผู้พำนักในสหรัฐฯ
                    ของผู้เช่าอีกด้วย
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับโบรชัวร์ฉบับเต็ม กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐทั่วไป กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-certificate-general-states.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐนิวยอร์ก กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    สำหรับใบรับรองนโยบายสำหรับรัฐคอนเนตทิคัต, อินเดียนา, แคนซัส,
                    มิสซูรี, มอนแทนา, เวอร์มอนต์, เวอร์จิเนีย และ วอชิงตัน กรุณา{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      คลิกที่นี่
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
