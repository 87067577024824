import React from "react";

export default class ET extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">รถยนต์และบริการ</li>
                    <li className="breadcrumb-item">ผลิตภัณฑ์และบริการ</li>
                    <li className="breadcrumb-item">สหรัฐอเมริกาและแคนาดา</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      Avis e-Toll
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    บริการเช่ารถผ่านทางด่วนอิเล็กทรอนิกส์และการชำระค่าทางด่วนแบบไร้เงินสด
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <p className="text-avis mt-2">
                  Avis
                  ยินดีที่จะให้บริการจัดการค่าทางด่วนแบบไร้เงินสดเพื่อความสะดวกของคุณ
                  กรุณาเลือกรัฐที่คุณรับรถสำหรับรายละเอียดเฉพาะ
                </p>
                <p className="text-avis mt-2">
                  บริการ Avis e-Toll มีให้ในภูมิภาคตะวันออกเฉียงเหนือ
                  (จากรัฐเมนไปถึงนอร์ทแคโรไลนา), แคลิฟอร์เนีย, พื้นที่ชิคาโก,
                  โคโลราโด, ฟลอริดา, โอกลาโฮมา, เท็กซัส, วอชิงตัน และเปอร์โตริโก
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  ทางด่วนไร้เงินสด - แคนาดา
                </h3>
                <p className="text-avis mt-2">
                  โปรดระวังถนนที่อาจเป็น "ทางด่วนไร้เงินสด"
                  และผู้ขับขี่บนถนนเหล่านี้จะไม่สามารถชำระเงินสดได้หลังจากที่เข้าสู่ถนนเหล่านั้น
                  คุณจะถูกลงทะเบียนในบริการ e-Toll
                  ของเราโดยอัตโนมัติและจะถูกเรียกเก็บเงิน $17.00 บวก GST/HST
                  ต่อการเช่าหนึ่งครั้ง
                  พร้อมค่ากิโลเมตรที่เกิดขึ้นและค่าทางด่วนที่คิดตามอัตราสูงสุดที่หน่วยงานทางด่วนกำหนด
                  การเรียกเก็บเงินอาจใช้เวลา 4-8
                  สัปดาห์หลังจากการเช่าเพื่อบันทึกในบัญชีหรือบัตรเครดิต/บัตรเดบิตของคุณ
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  ภาคตะวันออกเฉียงเหนือ
                  (จากรัฐเมนถึงนอร์ทแคโรไลนาและพื้นที่ชิคาโก):
                </h3>
                <p className="text-avis mt-2">
                  แคลิฟอร์เนีย, โคโลราโด, เดลาแวร์, เขตโคลัมเบีย, ฟลอริดา,
                  จอร์เจีย, อิลลินอยส์, อินเดียนา, แคนซัส, เมน, แมริแลนด์,
                  แมสซาชูเซตส์, นิวแฮมป์เชียร์, นิวเจอร์ซีย์, นิวยอร์ก, โอไฮโอ,
                  โอกลาโฮมา, เพนซิลเวเนีย, โรดไอแลนด์, เท็กซัส, เวอร์จิเนีย,
                  วอชิงตัน, เวสต์เวอร์จิเนีย
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
