import React from "react";

export default class UG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">รถยนต์ & บริการ</li>
                    <li className="breadcrumb-item">ผลิตภัณฑ์ & บริการ</li>
                    <li className="breadcrumb-item">สหรัฐอเมริกา & แคนาดา</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      ระบบนำทาง GPS
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    ประหยัดข้อมูลมือถือของคุณ - จองด้วย GPS
                  </h3>
                  <p className="text-white">
                    นำทางการเดินทางของคุณโดยไม่ต้องใช้ข้อมูลมือถือ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">รายละเอียด</h3>
                <p className="text-avis mt-2">
                  เมื่อการเดินทางพาคุณไปยังสถานที่ใหม่ ๆ
                  ไม่มีเวลาที่จะลองค้นหาทาง ด้วย GPS คุณสามารถค้นหาที่อยู่
                  โรงแรม และสถานที่ท่องเที่ยว โดยไม่ต้องใช้ข้อมูลมือถือของคุณ
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal link-avis">
                  <u>
                    <i>เงื่อนไขและข้อกำหนด</i>
                  </u>
                </h3>
                <p className="text-avis mt-2">
                  เงื่อนไขและข้อกำหนดของระบบนำทาง GPS:
                </p>
                <ul>
                  <li className="text-avis mt-2">
                    •
                    ความน่าเชื่อถือของการนำทางขึ้นอยู่กับเวลาของการติดตั้งซอฟต์แวร์
                    รวมถึงข้อมูลที่ผู้ผลิตแผนที่ที่ติดตั้งจัดเตรียมไว้
                  </li>
                  <li className="text-avis mt-2">
                    • ข้าพเจ้า (ผู้เช่า) ยอมรับว่ารถเช่าอาจติดตั้ง GPS
                    ซึ่งใช้ดาวเทียมระบบกำหนดตำแหน่งทั่วโลก
                  </li>
                  <li className="text-avis mt-2">
                    • ข้าพเจ้ารับทราบว่า GPS
                    อาจมีข้อจำกัดโดยเงื่อนไขของชั้นบรรยากาศหรือภูมิประเทศ
                  </li>
                  <li className="text-avis mt-2">
                    • ข้าพเจ้ารับทราบเพิ่มเติมว่า GPS
                    อาจไม่สามารถทำงานได้หากถูกแก้ไขหรือเสียหาย
                    หรือในกรณีที่เกิดอุบัติเหตุหรือระบบดาวเทียมถูกกีดขวาง
                    และไม่สามารถทำงานได้
                  </li>
                  <li className="text-avis mt-2">
                    • ลูกค้ามีความรับผิดชอบต่อค่าใช้จ่ายทั้งหมดในกรณีที่ GPS
                    สูญหาย ถูกขโมย หรือเสียหาย รวมถึงส่วนประกอบทั้งหมดของมัน
                  </li>
                  <li className="text-avis mt-2">
                    • ผู้เช่ายินยอมที่จะปล่อยให้ Avis พ้นจากความรับผิดชอบใดๆ
                    สำหรับความล้มเหลวของ GPS
                  </li>
                  <li className="text-avis mt-2">
                    • GPS สามารถขอได้ที่เคาน์เตอร์เช่ารถ
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
