import React from "react";
import { Link } from "react-router-dom";

const CS = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">รถยนต์และบริการ</li>
                  <li className="breadcrumb-item">ผลิตภัณฑ์และบริการ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    ที่นั่งเด็ก
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                Avis มีที่นั่งเด็ก
                คุณภาพสูงสำหรับทารกและเด็กเล็กเพื่อให้เหมาะกับสมาชิกที่อายุน้อยที่สุดในครอบครัวของคุณ
              </p>
              <p className="text-avis mt-2">
                กฎระเบียบสำหรับที่นั่งเด็กและตัวเลือกที่นั่งที่มีให้แตกต่างกันไปในแต่ละประเทศ
                เราขอแนะนำให้คุณตรวจสอบ{" "}
                <a
                  href="https://www.avis.co.uk/TermsAndCondition"
                  target="_blank"
                  rel="noreferrer"
                  className="link-avis"
                >
                  ข้อกำหนดและเงื่อนไข
                </a>{" "}
                สำหรับรายละเอียดเพิ่มเติม
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                <b>โดยทั่วไป -</b>
              </p>
              <p className="text-avis mt-2">
                ที่นั่งเด็กทารกเหมาะสำหรับเด็กตั้งแต่เกิดถึง 12
                เดือนและมีน้ำหนักไม่เกิน 13 กิโลกรัม
              </p>
              <p className="text-avis mt-2">
                ที่นั่งเด็กเล็กเหมาะสำหรับเด็กอายุ 9 เดือนถึง 4
                ปีและมีน้ำหนักระหว่าง 9 ถึง 18 กิโลกรัม
              </p>
              <p className="text-avis mt-2">
                ที่นั่งเด็กเหมาะสำหรับเด็กอายุ 4 ถึง 11 ปีและมีน้ำหนักระหว่าง 15
                ถึง 36 กิโลกรัม ขณะที่ที่นั่งเสริมเหมาะสำหรับเด็กอายุ 8 ถึง 11
                ปีและมีน้ำหนักระหว่าง 20 ถึง 40 กิโลกรัม
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                โปรดทราบว่าคำอธิบายข้างต้นอาจไม่เหมือนกันในทุกประเทศ
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CS;
