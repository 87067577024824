import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import GL from "../components/globallocation/GL";

const GlobalLocation = () => {
    return (
        <>
            <HeaderOne />
            <GL />
            <FooterOne />
        </>
    )
}

export default GlobalLocation;