import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="container py-5">
      <h1 className="text-dark">ติดต่อบริการลูกค้า AVIS</h1>
      <p className="text-dark">
        คุณต้องการพูดคุยกับตัวแทนบริการลูกค้าของ Avis หรือไม่?
        สำหรับความช่วยเหลือในสหรัฐอเมริกา โทร <b>1-800-352-7900</b>.
        เยี่ยมชมหน้า <Link className="link-avis">หมายเลขโทรศัพท์ทั่วโลก</Link>{" "}
        สำหรับความช่วยเหลือในประเทศอื่นๆ
        ลิงก์ที่เป็นประโยชน์สำหรับความต้องการของบริการลูกค้าทั่วไปจะพบได้ด้านล่าง
      </p>
    </div>
  );
};

export default Header;
