import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, CLIENT_ID, BRAND } from "../../core/constant.js";
import countries from "../../Countries.json";
import { useNavigate } from "react-router-dom";
import worldLocation from "../../avis_locations2.json";
import DatePicker from "react-datepicker";
import { times } from "../../core/time.js";
import { format } from "date-fns";
import { getToken } from "../../core/functions.js";

const HeroOne = () => {
  const navigate = useNavigate();

  const currentDate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);

  const [pickupDate, setPickupDate] = useState(currentDate);
  const [dropoffDate, setDropoffDate] = useState(
    currentDate.getTime() + 3 * 24 * 60 * 60 * 1000
  );
  const [pickupTime, setPickupTime] = useState("08:00 AM");
  const [dropoffTime, setDropoffTime] = useState("08:00 AM");
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null);
  const [selectedDropoffLocation, setSelectedDropoffLocation] = useState(null);
  const [pickupCoordinate, setPickupCoordinate] = useState([]);
  const [dropoffCoordinate, setDropoffCoordinate] = useState([]);

  const [minReturnDate, setMinReturnDate] = useState("");
  const [age, setAge] = useState("26");
  const [country, setCountry] = useState("ID");
  const [countryCode, setCountryCode] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [isShowOfferCode, setIsShowOfferCode] = useState(false);
  const [isShowWizardCode, setIsShowWizardCode] = useState(false);
  const [awdNumber, setAwdNumber] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [wizardNumber, setWizardNumber] = useState("");
  const [lastName, setLastName] = useState("");

  const [pickupQuery, setPickupQuery] = useState("");
  const [filteredPickupData, setFilteredPickupData] = useState([]);
  const [dropoffQuery, setDropoffQuery] = useState("");
  const [filteredDropoffData, setFilteredDropoffData] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (pickupDate) {
      setMinReturnDate(pickupDate.getTime() + 1 * 24 * 60 * 60 * 1000);
      setDropoffDate(pickupDate.getTime() + 2 * 24 * 60 * 60 * 1000);
    }
  }, [pickupDate]);

  // watcher Pickup Point
  useEffect(() => {
    if (pickupQuery.length >= 3) {
      handleInputPickupLocationChange(pickupQuery);
    }

    if (pickupQuery.length < 3) {
      setFilteredPickupData([]);
    }
  }, [pickupQuery]);

  // watcher Dropoff Point
  useEffect(() => {
    if (dropoffQuery.length >= 2) {
      handleInputDropoffLocationChange(dropoffQuery);
    }

    if (dropoffQuery.length < 3) {
      setFilteredDropoffData([]);
    }
  }, [dropoffQuery]);

  const token = async () => {
    await getToken();
  };

  useEffect(() => {
    token();
  }, []);

  // Watcher Pickup Coordinate
  useEffect(() => {
    if (selectedPickupLocation) {
      const fetchPickupCoordinate = async () => {
        try {
          const res = await axios.get(
            "https://stage.abgapiservices.com/cars/locations/v1",
            {
              params: {
                keyword: selectedPickupLocation.MNE,
                client_id: CLIENT_ID,
              },
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: CLIENT_ID,
              },
            }
          );
          const pickupData = res.data.locations.find(
            (item) =>
              item.brand === BRAND && item.code === selectedPickupLocation.MNE
          );
          if (pickupData) {
            setPickupCoordinate([
              pickupData.address.lat,
              pickupData.address.long,
            ]);

            // if (selectedPickupLocation.Country === "CA") {
            //   setAwdNumber("I017600");
            // }

            // setCountryCode(pickupData.address.countryCode);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchPickupCoordinate();
    }
  }, [selectedPickupLocation]);

  useEffect(() => {
    if (selectedDropoffLocation) {
      const fetchDropoffCoordinate = async () => {
        try {
          const res = await axios.get(
            "https://stage.abgapiservices.com/cars/locations/v1",
            {
              params: {
                keyword: selectedDropoffLocation.MNE,
                client_id: CLIENT_ID,
              },
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: CLIENT_ID,
              },
            }
          );
          const dropoffData = res.data.locations.find(
            (item) =>
              item.brand === BRAND && item.code === selectedDropoffLocation.MNE
          );
          if (dropoffData) {
            setDropoffCoordinate([
              dropoffData.address.lat,
              dropoffData.address.long,
            ]);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchDropoffCoordinate();
    }
  }, [selectedDropoffLocation]);

  useEffect(() => {
    if (pickupDate && pickupTime) {
      const combinedPickupDateTime = new Date(`${pickupDate}`);
    }

    // if (countryCode === "CA") {
    //   setAwdNumber("I017600");
    // }
  });

  const handleInputPickupLocationChange = (query) => {
    setPickupQuery(query);
    clearTimeout(timeoutId);

    if (query.length >= 3) {
      const timeout = setTimeout(async () => {
        const filtered = worldLocation.filter((item) => {
          let includesSearchTerm = false;

          Object.values(item).forEach((value) => {
            if (
              value &&
              typeof value === "string" &&
              value.toLowerCase().includes(query.toLowerCase())
            ) {
              includesSearchTerm = true;
              return;
            }
          });

          if (
            (item.Descriptor &&
              item.Descriptor.toLowerCase().includes("airport")) ||
            (item.Address1 &&
              item.Address1.toLowerCase().includes("airport")) ||
            (item.Address2 && item.Address2.toLowerCase().includes("airport"))
          ) {
            item.group = "Airport";
          } else if (
            item["Postal Code"] &&
            item["Postal Code"].toString().trim() !== ""
          ) {
            item.group = "Neighborhood";
          } else {
            item.group = "City";
          }

          return includesSearchTerm;
        });
        setFilteredPickupData(filtered);
      }, 500);

      setTimeoutId(timeout);
    } else {
      setFilteredPickupData([]);
    }
  };

  const handleInputDropoffLocationChange = (query) => {
    setDropoffQuery(query);
    clearTimeout(timeoutId);

    if (query.length >= 3) {
      const timeout = setTimeout(async () => {
        const filtered = worldLocation.filter((item) => {
          let includesSearchTerm = false;

          Object.values(item).forEach((value) => {
            if (
              value &&
              typeof value === "string" &&
              value.toLowerCase().includes(query.toLowerCase())
            ) {
              includesSearchTerm = true;
              return;
            }
          });

          if (
            (item.Descriptor &&
              item.Descriptor.toLowerCase().includes("airport")) ||
            (item.Address1 &&
              item.Address1.toLowerCase().includes("airport")) ||
            (item.Address2 && item.Address2.toLowerCase().includes("airport"))
          ) {
            item.group = "Airport";
          } else if (
            item["Postal Code"] &&
            item["Postal Code"].toString().trim() !== ""
          ) {
            item.group = "Neighborhood";
          } else {
            item.group = "City";
          }

          return includesSearchTerm;
        });
        setFilteredDropoffData(filtered);
      }, 500);

      setTimeoutId(timeout);
    } else {
      setFilteredDropoffData([]);
    }
  };

  const groupingPickupSearch = () => {
    const results = {};

    filteredPickupData.forEach((result) => {
      if (!results[result.group]) {
        results[result.group] = [];
      }

      results[result.group].push(result);
    });

    return Object.values(results);
  };

  const groupingDropoffSearch = () => {
    const results = {};

    filteredDropoffData.forEach((result) => {
      if (!results[result.group]) {
        results[result.group] = [];
      }

      results[result.group].push(result);
    });

    return Object.values(results);
  };

  const handlePickupLocationClick = (selectedLocation) => {
    if (selectedLocation) {
      setSelectedPickupLocation(selectedLocation);

      const descriptor = selectedLocation.Descriptor ?? "";
      const address = selectedLocation.Address1 ?? "";
      const postalCode = selectedLocation["Postal Code"] ?? "";
      const mne = selectedLocation.MNE ?? "";

      setPickupQuery(`${descriptor} ${address} ${postalCode} ${mne}`);
    }
  };

  const handleDropoffLocationClick = (selectedLocation) => {
    if (selectedLocation) {
      setSelectedDropoffLocation(selectedLocation);

      const descriptor = selectedLocation.Descriptor ?? "";
      const address = selectedLocation.Address1 ?? "";
      const postalCode = selectedLocation["Postal Code"] ?? "";
      const mne = selectedLocation.MNE ?? "";

      setDropoffQuery(`${descriptor} ${address} ${postalCode} ${mne}`);
    }
  };

  const handlePickupDateChange = (value) => {
    const selectedDate = new Date(value);

    setPickupDate(selectedDate);
  };

  const handleDropoffDateChange = (value) => {
    const selectedDate = new Date(value);

    setDropoffDate(selectedDate);
  };

  const handlePickupTimeChange = (value) => {
    setPickupTime(value);
  };

  const handleDropoffTimeChange = (value) => {
    setDropoffTime(value);
  };

  const handleSubmit = async () => {
    const apiDataForm = {
      date: pickupDate,
      time: pickupTime,
      location: selectedPickupLocation.MNE,
      return_location: !selectedDropoffLocation
        ? selectedPickupLocation.MNE
        : selectedDropoffLocation.MNE,
      return_date: dropoffDate,
      return_time: dropoffTime,
      user_id: "",
      age: "25+",
      country: "ID",
      vehicle: vehicle,
    };

    const formattedPickupDate = format(pickupDate, "yyyy-MM-dd");
    const formattedPickupDateTime = `${formattedPickupDate} ${pickupTime}`;
    const formattedDropoffDate = format(dropoffDate, "yyyy-MM-dd");
    const formattedDropoffDateTime = `${formattedDropoffDate} ${dropoffTime}`;

    const passingData = {
      pickupDate: formattedPickupDateTime,
      pickupTime: pickupTime,
      pickupLocation: selectedPickupLocation,
      dropoffLocation: !selectedDropoffLocation
        ? selectedPickupLocation
        : selectedDropoffLocation,
      dropoffDate: formattedDropoffDateTime,
      dropoffTime: dropoffTime,
      pickupCoordinate: pickupCoordinate,
      dropoffCoordinate: dropoffCoordinate,
      age: age,
      countryCode: "ID",
      vehicle: vehicle,
      awdNumber: awdNumber,
      couponCode: couponCode,
      wizardNumber: wizardNumber,
      lastName: lastName,
      countryPick: selectedPickupLocation.Country,
    };

    try {
      const bookingCheck = axios.post(
        `${API_URL}/api/booking/check`,
        apiDataForm,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      // if (awdNumber !== "") {
      //   const pickupIso = pickupDate.toISOString();
      //   const dropoffIso = dropoffDate.toISOString();

      //   const carsCatalog = axios.get(
      //     "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
      //     {
      //       params: {
      //         brand: BRAND,
      //         pickup_date: pickupIso.split(".", 1)[0],
      //         pickup_location: selectedPickupLocation.MNE,
      //         membership_code: wizardNumber,
      //         dropoff_date: dropoffIso.split(".", 1)[0],
      //         dropoff_location: selectedDropoffLocation.MNE,
      //         country_code: "ID",
      //         discount_code: awdNumber,
      //         coupon_code: couponCode,
      //       },
      //       headers: {
      //         Accept: "*/*",
      //         "Content-Type": "application/json",
      //         Authorization: "Bearer " + localStorage.getItem("bebe"),
      //         client_id: "04e19f27",
      //       },
      //     }
      //   );

      //   if (carsCatalog.data.vehicles.length > 0) {
      //     navigate("/choosecars", {
      //       state: passingData,
      //     });
      //   }
      // }

      navigate("/choosecars", {
        state: passingData,
      });
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 401) {
        alert("Please Sign In First");
      } else {
        alert(error.response && error.response.data.msg);
      }
    }
  };

  return (
    <section
      className="banner-one"
      style={{ backgroundImage: "linear-gradient(#be0026, #9b001e)" }}
    >
      <div className="banner-one__inner py-4">
        <div className="container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="title text-center py-4">
              <h1 style={{ color: "#fff" }}>
                ค้นหารถเช่าที่ดีที่สุดของคุณกับ AVIS
              </h1>
            </div>
            <div
              className="row mt-3 mb-3 p-2"
              style={{ backgroundColor: "rgb(255 255 255 / 20%)" }}
            >
              <div className="col-sm-12">
                <div className="row">
                  {/* Input Pickup Location */}
                  <div className="col-sm-6 p-0 border-end">
                    <div style={{ position: "relative" }}>
                      <input
                        required
                        type="text"
                        className="form-control input-avis py-3"
                        placeholder="กรอกสถานที่รับหรือรหัสไปรษณีย์ของคุณ"
                        id="locationValue"
                        style={{ borderRadius: "0px" }}
                        value={pickupQuery}
                        onChange={(e) =>
                          handleInputPickupLocationChange(e.target.value)
                        }
                      />
                      <div>
                        {filteredPickupData.length > 0 && (
                          <div
                            className="live-search bg-light p-2"
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              width: "100%",
                              maxHeight: "300px",
                              overflowY: "auto",
                            }}
                          >
                            {groupingPickupSearch().map(
                              (groupedResults, groupIndex) => (
                                <div key={groupIndex}>
                                  <div
                                    className="d-flex"
                                    style={{ borderBottom: "1px solid #ddd" }}
                                  >
                                    <h5>{groupedResults[0].group}</h5>
                                    <span className="ms-auto">
                                      {groupedResults.length} results
                                    </span>
                                  </div>
                                  {groupedResults.map((result, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        padding: "5px",
                                      }}
                                    >
                                      <p
                                        id="descriptionLoc"
                                        onClick={() =>
                                          handlePickupLocationClick(result)
                                        }
                                      >
                                        <strong className="text-primary">
                                          {result.Descriptor}
                                        </strong>
                                        , {result.Address1 ?? ""}{" "}
                                        {result?.city ?? ""}{" "}
                                        {result["Postal Code"] ?? ""}{" "}
                                        <strong className="text-primary">
                                          {result.MNE ?? ""}
                                        </strong>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Input Pickup Date */}
                  <div className="col-sm-3 p-0 border-end">
                    <DatePicker
                      wrapperClassName="datepicker"
                      endDate={new Date()}
                      selected={pickupDate}
                      onChange={(e) => handlePickupDateChange(e)}
                      className="form-control input-avis py-3"
                      dateFormat={"dd-MM-yyyy"}
                      minDate={currentDate}
                      value={pickupDate}
                    />
                  </div>

                  {/* Input Pickup Time */}
                  <div className="col-sm-3 p-0">
                    <select
                      name=""
                      id=""
                      required
                      className="form-control input-avis py-3"
                      style={{ borderRadius: "0px" }}
                      onChange={(e) => handlePickupTimeChange(e.target.value)}
                      value={pickupTime}
                    >
                      {times().map((data, i) => (
                        <option key={i} value={data.value}>
                          {data.field}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-6 p-0 border-end">
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        className="form-control input-avis py-3"
                        placeholder="กลับที่เดิม"
                        id="locationValue"
                        style={{ borderRadius: "0px" }}
                        value={dropoffQuery}
                        onChange={(e) =>
                          handleInputDropoffLocationChange(e.target.value)
                        }
                      />
                      {filteredDropoffData.length > 0 ? (
                        <div
                          className="live-search bg-light p-2"
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            width: "100%",
                          }}
                        >
                          {groupingDropoffSearch().map(
                            (groupedResults, groupIndex) => (
                              <div key={groupIndex}>
                                <div
                                  className="d-flex"
                                  style={{ borderBottom: "1px solid #ddd" }}
                                >
                                  <h5>{groupedResults[0].group}</h5>
                                  <span className="ms-auto">
                                    {groupedResults.length} results
                                  </span>
                                </div>
                                {groupedResults.map((result, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px",
                                    }}
                                  >
                                    <p
                                      id="descriptionLoc"
                                      onClick={() =>
                                        handleDropoffLocationClick(result)
                                      }
                                    >
                                      <strong className="text-primary">
                                        {result.Descriptor}
                                      </strong>
                                      , {result.Address1} {result?.city}{" "}
                                      {result["Postal Code"] ?? ""}{" "}
                                      <strong className="text-primary">
                                        {result.MNE}
                                      </strong>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="col-sm-3 p-0 border-end customDatePickerWidth"
                    style={{ backgroundColor: "black" }}
                  >
                    <DatePicker
                      wrapperClassName="datepicker"
                      selected={dropoffDate}
                      onChange={(e) => handleDropoffDateChange(e)}
                      value={dropoffDate}
                      minDate={minReturnDate}
                      dateFormat={"dd-MM-yyyy"}
                      className="form-control input-avis py-3"
                    />
                  </div>
                  <div className="col-sm-3 p-0">
                    <select
                      name=""
                      id=""
                      required
                      className="form-control input-avis py-3"
                      style={{ borderRadius: "0px" }}
                      onChange={(e) => handleDropoffTimeChange(e.target.value)}
                      value={dropoffTime}
                    >
                      {times().map((data, i) => (
                        <option value={data.value} key={i}>
                          {data.field}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-sm-4 p-0">
                    <div className="input-group">
                      <span className="input-group-text">อายุ</span>
                      <select
                        required
                        className="form-control"
                        style={{ borderRadius: "0px" }}
                        onChange={(e) => setAge(e.target.value)}
                        value={age}
                      >
                        <option value={"26"} selected>
                          25+
                        </option>
                        <option value={"24"}>24</option>
                        <option value={"23"}>23</option>
                        <option value={"22"}>22</option>
                        <option value={"21"}>21</option>
                        <option value={"20"}>20</option>
                        <option value={"19"}>19</option>
                        <option value={"18"}>18</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 p-0">
                    <div className="input-group">
                      <span className="input-group-text">ประเทศ</span>
                      <select
                        value={country}
                        required
                        className="form-control"
                        style={{ borderRadius: "0px" }}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        {countries.map((c, index) => (
                          <option
                            key={index}
                            value={c.code}
                            selected={c.name === "Indonesia" ? true : false}
                          >
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 p-0 ">
                    <button
                      type="button"
                      className="btn btn-light w-100"
                      style={{ borderRadius: "0px" }}
                      onClick={() => {
                        setIsShowWizardCode(!isShowWizardCode);
                        setIsShowOfferCode(false);
                      }}
                    >
                      หมายเลขตัวช่วยสร้าง Avis
                    </button>
                  </div>
                  <div className="col-lg-2 col-sm-6 p-0">
                    <button
                      type="button"
                      className="btn btn-light w-100"
                      style={{ borderRadius: "0px" }}
                      onClick={() => {
                        setIsShowOfferCode(!isShowOfferCode);
                        setIsShowWizardCode(false);
                      }}
                    >
                      รหัสข้อเสนอ{" "}
                    </button>
                  </div>
                  <div className="col-lg-2 col-sm-6 p-0">
                    <input
                      className="form-control"
                      placeholder="ประเภทยานพาหนะ"
                      style={{ borderRadius: "0px" }}
                      onChange={(e) => setVehicle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {isShowOfferCode && (
                <div className="container bg-light py-3">
                  <div className="row justify-content-start align-items-center">
                    <div className="col-sm-3">
                      <p className="text-center">ใส่รหัสส่วนลด</p>
                    </div>
                    <div className="col-sm-3">
                      <input
                        className="form-control"
                        type="text"
                        name="discount_code"
                        onChange={(e) => setAwdNumber(e.target.value)}
                        placeholder="AWD"
                      />
                    </div>
                    <div className="col-sm-3">
                      <input
                        className="form-control"
                        type="text"
                        name="coupon_code"
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="รหัสคูปอง"
                      />
                    </div>
                  </div>
                </div>
              )}

              {isShowWizardCode && (
                <div className="container bg-light py-3">
                  <div className="row justify-content-start align-items-center">
                    <div className="col-sm-4">
                      <p className="text-center">
                        ป้อนหมายเลขตัวช่วยสร้างและนามสกุล
                      </p>
                    </div>
                    <div className="col-sm-3">
                      <input
                        className="form-control"
                        type="text"
                        name="wizard_number"
                        onChange={(e) => setWizardNumber(e.target.value)}
                        placeholder="หมายเลขตัวช่วยสร้าง"
                      />
                    </div>
                    <div className="col-sm-3">
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="นามสกุล"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row pt-4 pb-5">
              <div className="col-sm-12 text-center">
                <button className="btn btn-avis" type="submit">
                  เลือกรถของฉัน
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default HeroOne;
