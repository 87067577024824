import React from "react";
import { Link } from "react-router-dom";

const ContentCorporate = () => {
  return (
    <div className="container pb-4">
      <h5 className="text-dark mb-2">
        GET ALL THE LATEST INFORMATION ABOUT AVIS
      </h5>
      <p className="mb-2">
        <b>Company Overview</b>
      </p>
      <p className="mb-2">
        Avis Car Rental and its subsidiaries operate one of the world's
        best-known car rental brands with approximately 5,500 locations in more
        than 165 countries. Avis has a long history of innovation in the car
        rental industry and is one of the world's top brands for customer
        loyalty. Avis is owned by Avis Budget Group, Inc. (NASDAQ: CAR), which
        operates and licenses the brand throughout the world.{" "}
      </p>
      <p className="mb-2">
        <b>Quick History</b>
      </p>
      <p className="mb-2">
        Founded in 1946 by Warren Avis (who sold his interest in 1954), Avis was
        the first company to rent cars from airport locations. The company grew
        rapidly during the 1950's through franchised and corporate-owned
        expansion. In 1963, Avis introduced the award-winning "We try harder®"
        campaign and the tagline remains the company's rallying cry even today.
        Ten years later, Avis launched its innovative Wizard system, the
        company's proprietary reservation technology. Avis changed ownership
        multiple times in the 70's and 80's, becoming employee-owned in 1987.
        That same year, Avis introduced the Roving Rapid Return, a handheld
        computer terminal allowing customers to bypass the Avis counter. In
        1996, HFS Incorporated acquired Avis, which was then spun off as a
        separate public company, all but the franchising rights, Wizard system,
        and reservation operations. In 2000, the company introduced Avis
        Interactive, the first Internet-based reporting system for commercial
        accounts in the car rental industry, which was significantly enhanced
        and re-launched in 2006. In March 2001, Cendant Corporation purchased
        all of the outstanding shares of Avis Group Holdings and later that year
        moved its world headquarters to Parsippany, NJ. In 2006, Cendant Corp.
        separated into four independent companies: Realogy, Wyndham Worldwide,
        Travelport and Avis Budget Group, Inc., which is now the parent company
        of Avis Car Rental. In 2011, Avis Budget Group acquires Avis Europe plc.
        The transaction re-unites Avis Budget Group with its licensee for
        Europe, the Middle East, Africa and Asia.
      </p>
      <p className="mb-2">
        <b>World Headquarters</b>
      </p>
      <p className="mb-2">6 Sylvan Way, Parsippany, NJ 07054</p>
      <p className="mb-2">(973) 496-3500</p>
      <p className="mb-2">
        <b>Special Service Offerings</b>
      </p>
      <p className="mb-2">
        <b>Avis Preferred</b>, the Company's customer loyalty program, enables
        members to skip the lines and go straight to their vehicle at many
        locations. Members are also eligible to earn Avis Preferred Points - one
        point for every dollar they spend on vehicle rentals and two points for
        every dollar they spend on optional products such as GPS, satellite
        radio and child safety seats. Earned points can be redeemed for
        complimentary Avis rental days, upgrades and select optional products.
      </p>
      <p className="mb-2">
        <b>Avis Preferred® Select & Go</b>, the Company's vehicle
        selection/upgrade service, is available in the United States and Canada.
      </p>
      <p className="mb-2">
        <b>Avis Access</b> is the rental car industry's first comprehensive
        accessibility program for travelers with disabilities.
      </p>
      <p className="mb-2">
        <b>Sales Programs</b>
      </p>
      <p className="mb-2">
        <b>Avis for Business</b> is a program designed to help small and
        mid-size businesses by offering low corporate rates, complimentary
        rental days, and free enrollment in Avis Preferred service.
      </p>
      <p className="mb-2">
        <b>Avis Meeting & Group Services</b> is designed to help group,
        association and family gatherings with their ground transportation needs
        and offers special pricing and other assistance for meetings using five
        or more cars.
      </p>
      <p className="mb-2">
        <b>Avis Corporate Sales</b> offers large and mid-sized business managers
        negotiated rates, frequency benefits and optional coverages.
      </p>
      <p className="mb-2">
        <b>Avis Car Sales</b> is designed for those looking to purchase quality,
        well-maintained off-rental vehicles. Offered in collaboration with
        Innovative Funding Services, the program features a wide selection of
        late-model cars, great prices, a no-haggle, no-hassle car buying process
        and the ultimate test drive to get the full experience of driving the
        vehicle.
      </p>
      <p className="mb-2">
        <b>Avis Websites</b>
      </p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          <Link className="link-avis">www.avis.com</Link> - For customer
          reservations and information
        </li>
        <li>
          <Link className="link-avis">www.avisagent.com</Link> and{" "}
          <Link className="link-avis">www.unlimited-rewards.com</Link> - For
          travel agents
        </li>
        <li>
          <Link className="link-avis">www.avisinteractive.com</Link> - For
          corporate travel managers
        </li>
        <li>
          <Link className="link-avis">www.aviscarsales.com</Link> - For those
          looking to purchase a used vehicle
        </li>
      </ul>
      <p className="mb-2">
        <b>Avis Budget Group, Inc. Executive Staff</b>
      </p>
      <p className="mb-2">Joe Ferraro, President and Chief Executive Officer</p>
      <p className="mb-2">Brian Choi, Chief Financial Officer</p>
      <p className="mb-2">Izzy Martins, Executive Vice President, Americas</p>
      <p className="mb-2">Keith Rankin, President, International</p>
      <p className="mb-2">
        Ned Linnen, Executive Vice President and Chief Human Resources Officer
      </p>
      <p className="mb-2">
        Jean Sera, Senior Vice President, General Counsel, Chief Compliance
        Officer And Corporate Secretary
      </p>
      <p className="mb-2">
        Gerard Insall, Executive Vice President and Chief Information Officer
      </p>
      <p className="mb-2">
        <b>Recent Awards and Recognition</b>
      </p>
      <p className="mb-2">WORLD</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "World's Leading Car Rental Website - Avis.com" - World Travel Awards
          (2014)
        </li>
        <li>
          "World's Leading Car Rental Company" - World Travel Awards (2014)
        </li>
        <li>
          "World's Leading Luxury Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>"Best Car Rental Worldwide" - Business Traveller UK (2014)</li>
      </ul>
      <p className="mb-2">NORTH AMERICA</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "North America's Leading Luxury Car Rental Company" - World Travel
          Awards (2014)
        </li>
        <li>
          "North America's Leading Car Rental Company" - World Travel Awards
          (2014)
        </li>
      </ul>
      <p className="mb-2">U.S.A.</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          One of the top 40 consumer brands on the 2015 Brand Keys Loyalty
          Leaders List (2015)
        </li>
        <li>
          "Gold Winner - Ground Transportation/Marketing" - (Unlimited Rewards)
          Travel Weekly Magellan Awards (2015)
        </li>
        <li>"2015 Most Admired Car Rental Company" - The Beat (2015)</li>
        <li>"Ground Transportation Provider of the Year" - NetJets (2015)</li>
        <li>
          One of America's top 50 "green" brands - 2015 Customer Loyalty
          Engagement Index - Brand Keys (2015)
        </li>
        <li>"Supplier of the Year" - The Boeing Company (2015)</li>
        <li>
          #1 Car Rental Company in the 2015 Customer Loyalty Engagement Index -
          Brand Keys (2015)
        </li>
        <li>
          "Best Social Media Presence - Ground Transportation" - Skiftie Awards
          (2014)
        </li>
        <li>
          "Gold Winner - Ground Transportation/Loyalty Program" - (Unlimited
          Budget) Travel Weekly Magellan Awards (2014)
        </li>
        <li>
          #1 Car Rental Company in the 2014 Customer Loyalty Engagement Index -
          Brand Keys (2014)
        </li>
        <li>
          "One of America's Top 50 Organizations for Multicultural Business
          Opportunities" - DiversityBusiness.com (2014)
        </li>
        <li>
          "One of America's Top Corporations for Women's Business Enterprises" -
          WBENC (2014)
        </li>
      </ul>
      <p className="mb-2">CANADA</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "Agents' Choice Winner - Car Rental Agency" - Baxter Travel Media
          (2014)
        </li>
      </ul>
      <p className="mb-2">LATIN AMERICA/CARIBBEAN</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "Mexico and Central America's Leading Business Car Rental Company" -
          World Travel Awards (2014)
        </li>
        <li>
          "Mexico and Central America's Leading Car Rental Company" - World
          Travel Awards (2014)
        </li>
        <li>
          "South America's Leading Car Rental Company" - World Travel Awards
          (2014)
        </li>
      </ul>
      <p className="mb-2">AUSTRALIA/NEW ZEALAND</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "Leading Rental Car Company" - The Travel Industry Awards - Travel
          Digest (2014)
        </li>
        <li>
          "Mexico and Central America's Leading Car Rental Company" - World
          Travel Awards (2014)
        </li>
        <li>
          "South America's Leading Car Rental Company" - World Travel Awards
          (2014)
        </li>
      </ul>
      <p className="mb-2">ASIA</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "Asia's Leading Business Car Rental Company" - World Travel Awards
          (2013)
        </li>
        <li>"Asia's Leading Car Hire" - World Travel Awards (2013)</li>
        <li>
          "Australasia's Leading Business Car Hire" - World Travel Awards (2013)
        </li>
      </ul>
      <p className="mb-2">EMEA</p>
      <ul className="similarCarOpt ps-4 mb-2">
        <li>
          "Europe's Leading Business Car Rental Company" - World Travel Awards
          (2015)
        </li>
        <li>
          "Africa's Leading Business Car Rental Company" - World Travel Awards
          (2015)
        </li>
        <li>
          "Africa's Leading Leisure Car Rental Company" - World Travel Awards
          (2015)
        </li>
        <li>
          "Kenya's Leading Car Rental Company" - World Travel Awards (2015)
        </li>
        <li>
          "Morocco's Leading Car Rental Company" - World Travel Awards (2015)
        </li>
        <li>
          "Nigeria's Leading Car Rental Company" - World Travel Awards (2015)
        </li>
        <li>
          "South Africa's Leading Car Rental Company" - World Travel Awards
          (2015)
        </li>
        <li>
          "Indian Ocean's Leading Car Rental Company" - World Travel Awards
          (2015)
        </li>
        <li>
          "Middle East's Leading Business Car Rental Company" - World Travel
          Awards (2015)
        </li>
        <li>"Best Car Hire Company" - British Travel Awards (2014)</li>
        <li>"Best Rental Company in Europe" - Business Traveller UK (2014)</li>
        <li>
          "Europe's Leading Business Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>
          "Africa's Leading Business Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>
          "Africa's Leading Leisure Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>
          "Kenya's Leading Car Rental Company" - World Travel Awards (2014)
        </li>
        <li>
          "Morocco's Leading Car Rental Company" - World Travel Awards (2014)
        </li>
        <li>
          "Nigeria's Leading Car Rental Company" - World Travel Awards (2014)
        </li>
        <li>
          "South Africa's Leading Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>
          "Indian Ocean's Leading Car Rental Company" - World Travel Awards
          (2014)
        </li>
        <li>
          "Best Car Rental Company - Avis South Africa" - Business Traveller
          Africa (2014)
        </li>
      </ul>
      <p className="mb-2">
        <b>For Reservations</b>
      </p>
      <p className="mb-2">
        (888) 777-AVIS or visit <Link className="link-avis">www.avis.com</Link>{" "}
        / (888) TRY-HARD for travelers with disabilities
      </p>
      <p className="mb-2">
        <b>For Media Relations Inquiries</b>
      </p>
      <p className="mb-4">
        Call (973) 496-3916 or email{" "}
        <Link className="link-avis">pr@avisbudget.com</Link>
      </p>
    </div>
  );
};

export default ContentCorporate;
