import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/productservice.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-white">ผลิตภัณฑ์และบริการเช่ารถ</h1>
                <p className="text-white">
                  เรียนรู้เพิ่มเติมเกี่ยวกับผลิตภัณฑ์ของ Avis
                  ที่จะทำให้ประสบการณ์เช่ารถของคุณง่ายและสนุกมากขึ้น
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/usa`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">
                      ผลิตภัณฑ์และบริการสำหรับการเช่าในสหรัฐอเมริกาและแคนาดา
                    </h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      ค้นพบผลิตภัณฑ์และบริการเฉพาะที่จะเพิ่มพูนประสบการณ์การเช่ารถของคุณกับ
                      Avis ในสหรัฐอเมริกาและแคนาดา
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">เรียนรู้เพิ่มเติม</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/additional-driver`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">ผู้ขับขี่เพิ่มเติม</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      แบ่งปันการขับขี่กับคนอื่นเพื่อช่วยลดภาระในการขับรถในระยะทางยาว
                      เพิ่มผู้ขับขี่เพิ่มเติมแล้วพวกเขาจะได้รับความคุ้มครองตามข้อตกลงของคุณ
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">เรียนรู้เพิ่มเติม</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/child-seats`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">ที่นั่งสำหรับเด็ก</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      เรามีที่นั่งสำหรับทารกและเด็กคุณภาพดี
                      แต่มีข้อจำกัดตามความพร้อมที่เคาน์เตอร์
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">เรียนรู้เพิ่มเติม</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/fuel-option`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">ตัวเลือกเชื้อเพลิง</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      เชื้อเพลิงใกล้หมดหรือไม่มีเวลา? Avis
                      จะดูแลเรื่องการเติมน้ำมันทั้งหมดของคุณ...หากคุณต้องการให้เราดูแล
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">เรียนรู้เพิ่มเติม</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/onewaycarrental`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">เช่ารถเที่ยวเดียว</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      เดินทางจากจุด A ไปยังจุด B
                      โดยไม่ต้องย้อนกลับไปยังจุดเริ่มต้นเพื่อคืนรถเช่าของคุณ
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">เรียนรู้เพิ่มเติม</label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
