import React from "react";
import Card from "../../common/card/Card";

const ContentFraudScam = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row">
          <Card
            title="คำถามที่พบบ่อย"
            description="ที่นี่คุณจะพบคำตอบสำหรับคำถามทั่วไปเกี่ยวกับการจัดส่ง การชำระเงิน การคืนสินค้า และอื่นๆ ค้นหาข้อมูลที่คุณต้องการเพื่อประสบการณ์การช้อปปิ้งที่ราบรื่นบนเว็บไซต์ของเรา"
            link="https://faq.avis.co.uk/"
          />
          <Card
            title="ข้อกำหนดและเงื่อนไข"
            description="อ่านข้อกำหนดและเงื่อนไขของเราเพื่อทำความเข้าใจสิทธิ์และหน้าที่ของคุณเมื่อใช้เว็บไซต์ของเรา ค้นหาข้อมูลเกี่ยวกับการชำระเงิน การจัดส่ง การคืนสินค้า นโยบายความเป็นส่วนตัว และข้อกำหนดการใช้งานเว็บไซต์ เมื่ออ่านข้อกำหนดและเงื่อนไขของเราแล้ว คุณยอมรับที่จะปฏิบัติตามกฎทั้งหมดที่กำหนดไว้สำหรับการใช้งานเว็บไซต์ของเรา"
            link="https://www.avis.co.uk/TermsAndCondition"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentFraudScam;
