import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import VMC from "../components/viewmodifycancel/vmc";
import Title from "../Title";
import { useNavigate } from "react-router-dom";

const ViewModifyCancel = () => {
  Title("Reservation Confirmation/Cancellation");
  const navigate = useNavigate();
  return (
    <>
      <HeaderOne />
      <VMC navigate={navigate} />
      <FooterOne />
    </>
  );
};

export default ViewModifyCancel;
