import React from "react";
import { Link } from "react-router-dom";

export default class Pro extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}/protection`}>
                        ผลิตภัณฑ์และบริการ
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      การคุ้มครองและการประกันภัย
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-black text-up pb-2">
                  การคุ้มครองและการประกันภัย
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-4" style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Extended-Roadside-Assistance`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/RSN.avif"}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          บริการช่วยเหลือฉุกเฉินบนถนน
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      เพื่อประสบการณ์การขับขี่ที่ปลอดภัยที่สุด
                      เพิ่มบริการช่วยเหลือฉุกเฉินบนถนนให้กับการจองของคุณจาก Avis
                      บริการนี้จะมอบการช่วยเหลือฉุกเฉินบนท้องถนนที่รวดเร็วและเชื่อถือได้
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Loss-Damage-Waiver-(LDW)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={
                              publicUrl +
                              "assets/images/resources/protection-ldw.avif"
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          การสละความรับผิดชอบความเสียหาย (LDW)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Avis เสนอ LDW
                      ซึ่งเป็นตัวเลือกที่ช่วยบรรเทาความรับผิดชอบทางการเงิน
                      หากรถเช่าของคุณเกิดความเสียหายหรือถูกขโมย
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Personal-Accident-Insurance-(PAI)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/PAI.png"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          ประกันอุบัติเหตุส่วนบุคคล (PAI)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      คุ้มครองคุณและผู้โดยสารด้วยประกันภัยและความคุ้มครองทางการแพทย์ในกรณีที่เกิดอุบัติเหตุ
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Personal-Effect-Insurance-(PEP)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/PEP.png"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          ประกันสิ่งของส่วนบุคคล (PEP)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      คุ้มครองทรัพย์สินส่วนบุคคลของคุณในระหว่างการเดินทาง
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Additional-Liability-Insurance-(ALI)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/ALI.avif"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          ประกันความรับผิดเพิ่มเติม (ALI)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      ALI
                      คือการประกันภัยรถยนต์ที่ปกป้องคุณและผู้ขับขี่ที่ได้รับอนุญาตทุกคนจากการเรียกร้องค่าชดเชยจากบุคคลที่สามเนื่องจากการบาดเจ็บทางร่างกายหรือความเสียหายต่อทรัพย์สิน
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Emergency-Sickness-Plan-(ESP)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={
                              publicUrl +
                              "assets/images/resources/ESP_Icon.avif"
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          แผนการรักษาโรคฉุกเฉิน (ESP)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      ESP
                      ช่วยให้คุณมั่นใจได้ว่าคุณได้รับความคุ้มครองในกรณีที่เจ็บป่วยกะทันหันในระหว่างการเดินทาง
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      เรียนรู้เพิ่มเติม{" "}
                    </label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="container flex row pt-4">
              <hr className="col-sm-12" />
            </div>
            <div className="row pt-4">
              <h4 className="text-up text-black fw-normal">
                สำหรับการเช่ารถในส่วนอื่นๆ ของโลก
              </h4>
              <p className="">
                เพื่อความอุ่นใจสูงสุดในระหว่างการเช่ารถของคุณ
                เรามีแพ็คเกจคุ้มครองเพิ่มเติม เพื่อช่วยลดค่าความเสียหายส่วนเกิน
                หรือเพื่อคุ้มครองและสนับสนุนคุณ
                ในกรณีที่เกิดอุบัติเหตุหรือรถเสีย
              </p>
              <p className="">
                ที่เคาน์เตอร์เช่า คุณสามารถเพิ่มความคุ้มครองเพิ่มเติม
                สำหรับการเช่ารถของคุณ
              </p>
            </div>

            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    การคุ้มครองความเสียหายจากการชน (CDW) และ
                    การคุ้มครองความเสียหายจากการชนขั้นสูง (SCDW)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                CDW ช่วยลดค่าความเสียหายส่วนเกิน
                หมายความว่าหากเกิดความเสียหายใหม่ที่ไม่เกิดจากความประมาท
                ต่อตัวถังภายนอกรถ
                คุณจะจ่ายค่าความเสียหายที่ลดลงอย่างมากหรือไม่มีค่าใช้จ่ายเลย
                SCDW ช่วยลดค่าความเสียหายส่วนเกินลงอีก
                ในบางประเทศจะเป็นส่วนเกินเป็นศูนย์ เมื่อเลือกผลิตภัณฑ์นี้แล้ว
                ผลิตภัณฑ์นี้จะถูกเพิ่มในสัญญาเช่าของคุณ
                ในช่วงเริ่มต้นการเช่าพร้อมกับค่าธรรมเนียมที่มีการแจ้งไว้ล่วงหน้า
                ซึ่งจะถูกเรียกเก็บกับบัตรชำระเงินของคุณเมื่อสิ้นสุดการเช่ารถ
                พร้อมกับค่าใช้จ่ายอื่นๆ ในการเช่ารถ
                เป็นไปตามข้อกำหนดและเงื่อนไขท้องถิ่น
              </label>
            </div>

            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    การคุ้มครองการโจรกรรม (TP) และ การคุ้มครองการโจรกรรมขั้นสูง
                    (STP)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                TP ช่วยลดค่าความเสียหายส่วนเกิน
                หมายความว่าหากรถเช่าของคุณถูกขโมย
                คุณจะจ่ายค่าความเสียหายที่ลดลงอย่างมากหรือไม่มีค่าใช้จ่ายเลย STP
                ช่วยลดค่าความเสียหายส่วนเกินลงอีก
                ในบางประเทศจะเป็นส่วนเกินเป็นศูนย์
                ตรวจสอบจำนวนส่วนเกินที่ระบุไว้ในสัญญาเช่าของคุณ
                เพื่อดูจำนวนเงินสูงสุดที่ผู้ขับขี่จะต้องรับผิดชอบ
                ในกรณีที่รถเช่าถูกขโมย เมื่อเลือกผลิตภัณฑ์นี้แล้ว
                ผลิตภัณฑ์นี้จะถูกเพิ่มในสัญญาเช่าของคุณในช่วงเริ่มต้นการเช่าพร้อมกับค่าธรรมเนียมที่มีการแจ้งไว้ล่วงหน้า
                ซึ่งจะถูกเรียกเก็บกับบัตรชำระเงินของคุณเมื่อสิ้นสุดการเช่ารถ
                พร้อมกับค่าใช้จ่ายอื่นๆ ในการเช่ารถ
                เป็นไปตามข้อกำหนดและเงื่อนไขท้องถิ่น
              </label>
            </div>

            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    การช่วยเหลือฉุกเฉินพิเศษ (RSN)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                RSN เป็นผลิตภัณฑ์เครือข่ายความปลอดภัยบนท้องถนนของเรา
                นอกเหนือจากความช่วยเหลือฉุกเฉินมาตรฐาน
                ที่รวมอยู่ในการเช่ารถของคุณแล้ว บริการช่วยเหลือฉุกเฉินพิเศษ
                (หรือบริการช่วยเหลือฉุกเฉินพรีเมียม)
                ของเราครอบคลุมปัญหาที่ไม่ใช่ปัญหาทางกลไกที่คุณอาจเผชิญ
                ผลิตภัณฑ์นี้จะช่วยกำจัดค่าใช้จ่ายที่เกี่ยวข้องกับการซ่อมรถ เช่น
                การกระตุ้นแบตเตอรี่ หรือ ค่าลากจูง เมื่อเลือก RSN แล้ว
                ผลิตภัณฑ์นี้จะรวมอยู่ในสัญญาเช่าของคุณ
                และจะถูกเรียกเก็บเมื่อสิ้นสุดการเช่ารถ พร้อมกับค่าใช้จ่ายอื่นๆ
                ในการเช่ารถ เป็นไปตามข้อกำหนดและเงื่อนไขท้องถิ่น
              </label>
            </div>

            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    ประกันอุบัติเหตุส่วนบุคคล (PAI) หรือ
                    ประกันอุบัติเหตุส่วนบุคคลพิเศษ (SPAI)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                PAI ให้ความคุ้มครองเพิ่มเติมในกรณีเกิดอุบัติเหตุ
                รวมถึงการดูแลทางการแพทย์ฉุกเฉิน การขนส่ง สัมภาระที่สูญหาย
                และการคุ้มครองค่าใช้จ่ายที่ไม่คาดคิด
                เราได้ร่วมมือกับบริษัทประกันภัยในประเทศที่เช่าเพื่อเสนอการคุ้มครองชั้นหนึ่ง
                ให้กับคุณและผู้โดยสารของคุณ สำหรับการบาดเจ็บส่วนบุคคล
                การช่วยเหลือทางการแพทย์ฉุกเฉิน และการขนส่ง
              </label>
            </div>

            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    การคุ้มครองกระจกหน้า (WDW)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                การคุ้มครองกระจกหน้าช่วยลดค่าเสียหายส่วนเกินของกระจกหน้ารถลงเป็นศูนย์
                ซึ่งหมายความว่าหากเกิดความเสียหายใหม่ที่ไม่เกิดจากความประมาทต่อกระจกหน้ารถ
                คุณจะจ่ายค่าความเสียหายที่ลดลงอย่างมากหรือไม่มีค่าใช้จ่ายเลย
                เมื่อเลือกผลิตภัณฑ์นี้แล้ว
                ผลิตภัณฑ์นี้จะถูกเพิ่มในสัญญาเช่าของคุณ
                ในช่วงเริ่มต้นการเช่าพร้อมกับค่าธรรมเนียมที่มีการแจ้งไว้ล่วงหน้า
                ซึ่งจะถูกเรียกเก็บกับบัตรชำระเงินของคุณเมื่อสิ้นสุดการเช่ารถ
                พร้อมกับค่าใช้จ่ายอื่นๆ ในการเช่ารถ
              </label>
            </div>
          </div>
        </section>
      </>
    );
  }
}
