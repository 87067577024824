import React from "react";
import { Link } from "react-router-dom";

export default class AL extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/tripideas1.webp)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      backgroundColor: "rgb(212, 0, 42)",
      height: "200px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#fff",
    };
    const myBorder = {
      borderBottom: "2px solid #eaeaea",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-4">
            <div className="row">
              <div className="col-sm-12 text-white">
                <h3 className="text-white text-up">เช่ารถที่สนามบินกับ Avis</h3>
                <p className="text-avis my-2">
                  จากรถเก๋งขนาดเล็กสำหรับการเดินทางธุรกิจเดี่ยวไปจนถึงการเช่ารถมินิแวนและ
                  SUV สำหรับการพักผ่อนกับครอบครัว Avis
                  มีตัวเลือกการเช่ารถที่สนามบินหลากหลายเพื่อความสะดวกของคุณ
                  คุณสามารถเดินออกจากเครื่องบินและขึ้นรถเช่าได้อย่างรวดเร็ว
                  วิธีที่ดีที่สุดในการทำให้การเดินทางกับเด็กเล็กหรือสัมภาระนั้นง่ายและไม่มีปัญหา?
                  ยิ่งไปกว่านั้น ด้วยการเช่ารถที่สนามบิน
                  คุณสามารถชมสถานที่ระหว่างการประชุม
                  ทำการหยุดระหว่างทางไปยังจุดหมายปลายทาง
                  และเปลี่ยนแผนได้อย่างรวดเร็ว
                  การเดินทางของคุณจะกลายเป็นเรื่องที่เข้าถึงได้และน่าสนุกยิ่งขึ้นด้วยความสะดวกสบายของการเช่ารถที่สนามบินจาก
                  Avis
                </p>
                <b className="">
                  เรียกดูสถานที่สนามบินในสหรัฐอเมริกาของเราด้านล่าง:
                </b>
              </div>
            </div>
          </div>
        </section>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item myLink" aria-current="page">
                      กลับไปยังสถานที่
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row" style={myBorder}>
              <div className="col-sm-12">
                <h3 className="text-black text-up">
                  การเช่ารถที่สนามบินในงบประมาณ
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row mt-3">
              <div className="col-sm-12">
                <h5 className="text-avis text-up">สหรัฐอเมริกา</h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h3 className="text-black text-up">อลาสก้า</h3>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h3 className="text-black text-up">อลาบามา</h3>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  อัลเบเนีย
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-one" style={{ backgroundColor: "#000" }}>
          <div className="container py-3">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="text-white text-up">
                  ฉันจะเช่ารถที่สนามบินได้อย่างไร?
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  การเช่ารถสามารถทำได้ง่ายและรวดเร็ว เริ่มต้นโดยการจองผ่านแอพ
                  Avis, avis.com หรือโทรหา ต้องการเช่ารถที่สนามบินในนาทีสุดท้าย?
                  ไม่มีปัญหา ตรวจสอบที่เคาน์เตอร์ Avis เพื่อดูว่ามีรถเช่าหรือไม่
                  ด้วยเคล็ดลับและแนวทางจาก Avis
                  คุณสามารถออกจากสนามบินและออกไปบนท้องถนนได้อย่างรวดเร็ว
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <ol>
                  <li className="mb-3">
                    <b>+ เคาน์เตอร์ Avis:</b> รูปแบบสนามบินแต่ละแห่งแตกต่างกัน
                    เพื่อหาคลินิก Avis ที่คุณจะลงจอด
                    ให้มองหาป้ายที่ชี้ไปยังพื้นที่เช่ารถ
                    โดยทั่วไปแล้วเคาน์เตอร์เช่ารถจะอยู่ใกล้กับพื้นที่รับกระเป๋า
                    แต่บางสนามบินมีการเดินทางไปยังสถานที่เช่าที่นอกสถานที่
                  </li>
                  <li className="mb-3">
                    <b>+ การจอง:</b>{" "}
                    การทำการจองสามารถช่วยให้การเช่าของคุณราบรื่นยิ่งขึ้น
                    ยิ่งไปกว่านั้น ด้วยการจอง คุณจะสามารถเข้าถึงอัตราที่ดีกว่า
                  </li>
                  <li className="mb-3">
                    <b>+ ลูกค้าผู้มาที่เคาน์เตอร์:</b>{" "}
                    หากเครื่องบินของคุณลงจอดในเวลาทำการของเคาน์เตอร์ Avis
                    บนเว็บไซต์
                    คุณสามารถเดินไปหาพนักงานบริการลูกค้าและสอบถามว่ามีรถเช่าที่ว่างหรือไม่
                    โดยทั่วไปแล้วคุณต้องมีใบขับขี่ที่ถูกต้องและวิธีการชำระเงิน
                    หากมีรถว่างในช่วงเวลาที่คุณเดินทาง
                    คุณจะเซ็นเอกสารและไปได้เลย
                  </li>
                  <li className="mb-3">
                    <b>+ อัตราและความพร้อมใช้งาน:</b>{" "}
                    หากต้องการดูว่ามีรถเช่าและอัตราใดบ้าง ให้เลือกแท็บ "การจอง"
                    จากนั้นคลิก "ทำการจอง" ที่ด้านบนของหน้า
                    จากนั้นกรอกแบบฟอร์มวันที่และสถานที่และคลิก "เลือก รถของฉัน"
                  </li>
                  <li className="mb-3">
                    <b>+ คูปองและส่วนลด:</b>{" "}
                    ก่อนที่คุณจะจองการเช่ารถที่สนามบินบนเว็บไซต์หรือแอพของ Avis
                    ให้ตรวจสอบหน้า "ข้อเสนอในสหรัฐอเมริกา" ใต้แท็บ "ข้อเสนอ"
                    เมื่อคุณพบส่วนลดที่เกี่ยวข้อง
                    ให้เลือกและเริ่มกรอกแบบฟอร์มบนหน้านั้น
                  </li>
                  <li className="mb-3">
                    <b>+ การอัปเกรด:</b>{" "}
                    ก่อนที่คุณจะจองการเช่ารถที่สนามบินบนเว็บไซต์หรือแอพของ Avis
                    ให้ตรวจสอบหน้า "ข้อเสนอในสหรัฐอเมริกา" ใต้แท็บ "ข้อเสนอ"
                    เมื่อคุณพบส่วนลดที่เกี่ยวข้อง
                    ให้เลือกและเริ่มกรอกแบบฟอร์มบนหน้านั้น
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
