import React from "react";

const FuelOptions = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">รถยนต์และบริการ</li>
                  <li className="breadcrumb-item">ผลิตภัณฑ์และบริการ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    ตัวเลือกเชื้อเพลิง
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                ประหยัดเวลาเมื่อเติมเชื้อเพลิงล่วงหน้า
              </h3>
              <p className="text-avis mt-2">
                เมื่อคุณรับรถเช่าจาก Avis รถจะมีน้ำมันเต็มถัง
                คืนรถด้วยน้ำมันเต็มถังหลังการเดินทางและไม่มีค่าใช้จ่าย
              </p>
              <p className="text-avis mt-2">
                เพื่อให้คุณไม่ต้องยุ่งยากกับการเติมน้ำมัน
                เรามีตัวเลือกเชื้อเพลิงอื่น ๆ ให้เลือก
                คุณสามารถเลือกเติมน้ำมันล่วงหน้าและสั่งซื้อน้ำมันเต็มถังหรือดีเซล
                หรือเลือก EZ Fuel เมื่อคุณรับรถ
                ซึ่งเหมาะสำหรับการเดินทางระยะสั้น
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                อ่านต่อเพื่อดูว่าตัวเลือกเชื้อเพลิงของ Avis แบบไหนที่เหมาะกับคุณ
              </p>
              <h3 className="text-up text-black fw-normal">
                ตัวเลือกเชื้อเพลิงจาก Avis
              </h3>
              <h3 className="text-up text-black fw-normal mt-2">
                คืนรถด้วยน้ำมันเต็มถัง
              </h3>
              <p className="text-avis mt-2">
                ไม่มีค่าใช้จ่าย - แม้ว่าคุณจะเลือกตัวเลือกเติมน้ำมันล่วงหน้าหรือ
                EZ Fuel เมื่อคุณรับรถก็ตาม เพียงแค่คืนรถด้วยน้ำมันเต็มถัง
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                เติมน้ำมันล่วงหน้า
              </h3>
              <p className="text-avis mt-2">
                ซื้อน้ำมันเต็มถังล่วงหน้า - ในราคาเฉลี่ยของตลาด -
                เพื่อที่คุณจะไม่ต้องเติมน้ำมันก่อนคืนรถ
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">EZ Fuel</h3>
              <p className="text-avis mt-2">
                ขับรถไปไม่เกิน 75 ไมล์ (120 กม.)?
                สำหรับค่าธรรมเนียมคงที่ที่ชำระล่วงหน้า Avis
                จะจัดการเติมน้ำมันให้คุณ
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">จ่ายเมื่อคืน</h3>
              <p className="text-avis mt-2">
                หากคุณไม่มีเวลาเติมน้ำมันและไม่ได้เลือกเติมน้ำมันล่วงหน้า
                เราสามารถทำให้คุณได้ แต่ตัวเลือกนี้แพงที่สุด
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">หมายเหตุเล็กน้อย</h3>
              <ul>
                <li className="text-avis mt-2">
                  • เมื่อคุณรับรถจากสถานีเช่ารถ Avis
                  เจ้าหน้าที่ขายรถเช่าของเราจะแนะนำตัวเลือกต่าง ๆ
                  และจัดการตัวเลือกที่เหมาะสมกับความต้องการของคุณ
                </li>
                <li className="text-avis mt-2">
                  • สำหรับ <b>เติมน้ำมันล่วงหน้า</b>{" "}
                  จะไม่มีการคืนเงินสำหรับน้ำมันที่ไม่ได้ใช้ ยกเว้นคุณ{" "}
                  <b>คืนรถด้วยน้ำมันเต็มถัง</b> หากคุณคืนรถด้วยน้ำมันไม่เต็มถัง
                  จะไม่มีสิทธิ์ได้รับการคืนเงิน
                </li>
                <li className="text-avis mt-2">
                  • สำหรับ <b>จ่ายเมื่อคืน</b>{" "}
                  ราคาจะรวมถึงทั้งค่าธรรมเนียมน้ำมันและค่าบริการเติมน้ำมัน
                  แต่คุณจะจ่ายเฉพาะน้ำมันที่ใช้เท่านั้น
                  (ตรวจสอบกับเจ้าหน้าที่เมื่อคุณรับรถเช่าเพื่อยืนยันว่าค่าธรรมเนียมจะเป็นเท่าไร)
                </li>
                <li className="text-avis mt-2">
                  • สำหรับ <b>EZ Fuel</b> Avis
                  จะเติมน้ำมันให้กับรถของคุณในราคาเพียง £12 บวก VAT
                  หากคุณขับไปไม่เกิน 75 ไมล์/120 กม. หากคุณเติมน้ำมันเอง
                  นำใบเสร็จเมื่อคุณส่งรถคืนแล้วเราจะคืนเงินค่านี้ให้
                </li>
                <li className="text-avis mt-2">
                  • ราคาที่กล่าวถึงนี้สำหรับการเช่าที่เก็บจากสถานที่ "มาตรฐาน"
                  การเช่าจากสถานที่ "พรีเมี่ยม" จะมีราคาสูงกว่า
                  โปรดตรวจสอบราคาขณะทำการจอง
                </li>
                <li className="text-avis mt-2">
                  • สำหรับการเช่าในไซปรัส ผู้ขับขี่ที่ต้องการ{" "}
                  <b>คืนรถด้วยน้ำมันเต็มถัง</b> (โดยไม่มีค่าใช้จ่ายเพิ่มเติม)
                  ต้องแสดงใบเสร็จจากสถานีบริการน้ำมันในระยะ 10 กม. (6.2 ไมล์)
                  จากสถานีเช่ารถ Avis หากถังน้ำมันไม่เต็ม
                  จะมีการเรียกเก็บค่าธรรมเนียมน้ำมันตามราคาที่ตั้งในพื้นที่และค่าธรรมเนียมบริการเติมน้ำมันจำนวน
                  20.25 ยูโร (รวม VAT) จะถูกเรียกเก็บ <b>เติมน้ำมันล่วงหน้า</b>{" "}
                  ยังมีให้บริการในไซปรัส
                </li>
                <li className="text-avis mt-2">
                  • สำหรับการเช่าในมอลตาและโกโซ
                  รถเช่าทุกคันจะมาพร้อมกับน้ำมันครึ่งถัง ที่สถานีเช่า
                  ผู้ขับขี่สามารถซื้อ <b>เติมน้ำมันล่วงหน้า</b>{" "}
                  (น้ำมันเสนอในราคา ณ สถานีบริการในท้องถิ่น) หรือ{" "}
                  <b>คืนรถครึ่งถัง</b> โดยไม่มีค่าใช้จ่ายเพิ่มเติม
                  เพื่อคืนรถครึ่งถัง ผู้เช่าต้องคืนรถด้วยน้ำมันครึ่งถัง
                  (จะมีการเรียกเก็บเงินมัดจำที่จุดรับรถ
                  และจะหักออกเมื่อส่งคืนรถตามที่ตกลงกัน)
                  หากถังน้ำมันน้อยกว่าครึ่งถัง
                  จะมีการเรียกเก็บค่าธรรมเนียมน้ำมันตามราคาที่ตั้งในพื้นที่และค่าธรรมเนียมบริการเติมน้ำมันจำนวน
                  25 ยูโร (รวม VAT) จะถูกเรียกเก็บ
                </li>
                <li className="text-avis mt-2">
                  • โปรดทราบว่านโยบายเชื้อเพลิงจะแตกต่างกันไปในแต่ละประเทศ
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FuelOptions;
